import axios from "axios";
import React, { useState } from "react";
import { FaHeart } from "react-icons/fa";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoStar } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { defaultPieces } from "../../../assets/svg/pieces";
import { OpeningDataHomePage } from "../../../types";
import ChessboardPresentation from "../ChessboardPresentation/ChessboardPresentation";
import MenuOptions from "../MenuOptions/MenuOptions";
import { ConfirmationToast } from "../Toast/ToastDelete/ConfirmationToast";
import "./LessonCard.scss";

const API_URL = process.env.REACT_APP_API_URL || "";

interface LessonCardProps {
  dataOpening: OpeningDataHomePage;
  isEditing?: boolean;
  onDelete?: (id: string) => void; // Fonction appelée après suppression
}

const LessonCard: React.FC<LessonCardProps> = ({
  dataOpening,
  isEditing = false,
  onDelete,
}) => {
  const navigate = useNavigate();
  const [imageFailed, setImageFailed] = useState(false);

  // Naviguer vers la page d'ouverture ou d'édition
  const handleChangePage = () => {
    const path = isEditing
      ? `/createOpening/${dataOpening.title.toLocaleLowerCase()}-by-${dataOpening.creator.toLocaleLowerCase()}`
      : `/openings/${dataOpening.title.toLocaleLowerCase()}-by-${dataOpening.creator.toLocaleLowerCase()}`;
    navigate(path);
  };

  // Suppression de l'ouverture
  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${API_URL}/api/delete-opening/${dataOpening._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        if (onDelete) onDelete(dataOpening._id); // Appeler la fonction onDelete si elle est définie
        toast.success("Ouverture supprimée avec succès !");
      } else {
        throw new Error("Échec de la suppression.");
      }
    } catch (error) {
      toast.error(`Échec de la suppression: ${error}`);
    }
  };

  return (
    <div className="lessonCard" onClick={handleChangePage}>
      {/* Options d'édition (suppression) */}
      {isEditing && (
        <div
          className="menuOptionsWrapper"
          onClick={(e) => e.stopPropagation()} // Empêche la propagation du clic vers la carte
        >
          <MenuOptions
            options={[
              {
                label: "",
                icon: <FaRegTrashCan className="trash-icon" />,
                action: () =>
                  ConfirmationToast("Voulez-vous continuer ?", handleDelete),
              },
            ]}
          />
        </div>
      )}

      {/* Titre de l'ouverture */}
      <div className="lessonCard_title">
        <span>{dataOpening.title.toUpperCase()}</span>
      </div>

      {/* Contenu principal : Image ou ChessboardPresentation */}
      <div className="lessonCard_coverInfo">
        <div className="lessonCard_coverInfo_cover">
          {imageFailed ? (
            <ChessboardPresentation
              color={dataOpening.color}
              history={dataOpening.history}
            />
          ) : (
            <img
              src={`${API_URL}/api/get-opening-picture/${dataOpening._id}`}
              alt="pictureLesson"
              onError={() => setImageFailed(true)} // Bascule vers ChessboardPresentation en cas d'erreur
            />
          )}
        </div>

        {/* Informations supplémentaires */}
        <div className="lessonCard_coverInfo_info">
          <div className="lessonCard_coverInfo_info_likePremium">
            {/* Bouton Like */}
            <div className="lessonCard_coverInfo_info_likePremium_like">
              <FaHeart className="like-icon" />
              <span>{453}</span>
            </div>

            {/* Badge Premium */}
            <div
              className={`lessonCard_coverInfo_info_likePremium_badge ${
                !dataOpening.pathForPremium
                  ? "free"
                  : dataOpening.pathForPremium === 1
                  ? "premium"
                  : "free-premium"
              }`}
            >
              <span>
                {!dataOpening.pathForPremium
                  ? "Free"
                  : dataOpening.pathForPremium === 1
                  ? "Premium"
                  : "Free + Premium"}
              </span>
            </div>
          </div>

          {/* Couleur et niveau */}
          <div className="lessonCard_coverInfo_info_secondary">
            {/* Couleur des pièces */}
            <div className="lessonCard_coverInfo_info_secondary_color">
              {dataOpening.color === "w"
                ? defaultPieces({ width: "20", height: "20" }).wP
                : defaultPieces({ width: "20", height: "20" }).bP}
            </div>

            {/* Niveau sous forme d'étoiles */}
            <div className="filter-options_stars">
              {Array.from({ length: dataOpening.level }).map((_, i) => (
                <span key={i} className="star">
                  <IoStar />
                </span>
              ))}
            </div>
          </div>

          {/* Créateur */}
          <div className="lessonCard_coverInfo_info_auteur">
            <span>by {dataOpening.creator}</span>
          </div>
        </div>
      </div>

      {/* Description */}
      <div className="lessonCard_description">
        <p>{dataOpening.description}</p>
      </div>
    </div>
  );
};

export default LessonCard;
