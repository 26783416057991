// Loader.tsx
import { useEffect, useState } from "react";
import "./LoaderChess.scss";

type Pieces = { [key: string]: string };

interface Move {
  piece: string;
  from: string;
  to: string;
  delay: number;
}

const LoaderChess = () => {
  const [pieces, setPieces] = useState<Pieces>({});
  const [currentOpeningIndex, setCurrentOpeningIndex] = useState(0);

  // Définir les ouvertures connues
  const openings = [
    {
      name: "Ouverture Italienne",
      moves: [
        // Initialisation des pièces blanches
        { piece: "♙", from: "e2", to: "e4", delay: 0 },
        { piece: "♟︎", from: "e7", to: "e5", delay: 300 },
        { piece: "♘", from: "g1", to: "f3", delay: 600 },
        { piece: "♞", from: "b8", to: "c6", delay: 900 },
        { piece: "♗", from: "f1", to: "c4", delay: 1200 },
        { piece: "♝", from: "f8", to: "c5", delay: 1500 },
      ],
    },
    {
      name: "Défense Sicilienne",
      moves: [
        { piece: "♙", from: "e2", to: "e4", delay: 0 },
        { piece: "♟︎", from: "c7", to: "c5", delay: 300 },
        { piece: "♘", from: "g1", to: "f3", delay: 600 },
        { piece: "♟︎", from: "d7", to: "d6", delay: 900 },
        { piece: "♙", from: "d2", to: "d4", delay: 1200 },
        { piece: "♟︎", from: "c5", to: "d4", delay: 1500 },
        { piece: "♘", from: "f3", to: "d4", delay: 1800 },
        { piece: "♞", from: "g8", to: "f6", delay: 2100 },
      ],
    },
  ];

  useEffect(() => {
    let isMounted = true;

    const playOpening = async () => {
      // Réinitialiser l'échiquier
      setPieces(getInitialPositions());

      const currentOpening = openings[currentOpeningIndex];
      const totalMoves = currentOpening.moves.length;

      for (let i = 0; i < totalMoves; i++) {
        const move = currentOpening.moves[i];
        await new Promise((resolve) => setTimeout(resolve, move.delay));

        if (!isMounted) return;

        setPieces((prevPieces) => {
          const updatedPieces = { ...prevPieces };
          delete updatedPieces[move.from];
          updatedPieces[move.to] = move.piece;
          return updatedPieces;
        });
      }

      // Attendre un moment avant de passer à l'ouverture suivante
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (!isMounted) return;

      // Passer à l'ouverture suivante
      setCurrentOpeningIndex((prevIndex) => (prevIndex + 1) % openings.length);
    };

    playOpening();

    return () => {
      isMounted = false;
    };
  }, [currentOpeningIndex]);

  // Fonction pour obtenir les positions initiales des pièces
  const getInitialPositions = (): Pieces => {
    return {
      // Pièces blanches
      a1: "♖",
      b1: "♘",
      c1: "♗",
      d1: "♕",
      e1: "♔",
      f1: "♗",
      g1: "♘",
      h1: "♖",
      a2: "♙",
      b2: "♙",
      c2: "♙",
      d2: "♙",
      e2: "♙",
      f2: "♙",
      g2: "♙",
      h2: "♙",
      // Pièces noires
      a8: "♜",
      b8: "♞",
      c8: "♝",
      d8: "♛",
      e8: "♚",
      f8: "♝",
      g8: "♞",
      h8: "♜",
      a7: "♟︎",
      b7: "♟︎",
      c7: "♟︎",
      d7: "♟︎",
      e7: "♟︎",
      f7: "♟︎",
      g7: "♟︎",
      h7: "♟︎",
    };
  };

  // Générer le plateau d'échecs
  const renderBoard = () => {
    const squares = [];
    for (let rank = 8; rank >= 1; rank--) {
      for (let file = 0; file < 8; file++) {
        const squareColor = (rank + file) % 2 === 0 ? "light" : "dark";
        const position = `${String.fromCharCode(97 + file)}${rank}`;
        const piece = pieces[position];

        // Dans renderBoard()
        squares.push(
          <div
            key={position}
            className={`square ${squareColor}`}
            style={{ position: "relative" }}
          >
            {piece && <span className="piece">{piece}</span>}
          </div>
        );
      }
    }
    return squares;
  };

  return (
    <div className="chess-loader">
      <div className="chess-board">{renderBoard()}</div>
      <p className="opening-name">{openings[currentOpeningIndex].name}</p>
    </div>
  );
};

export default LoaderChess;
