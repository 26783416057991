// HorizontalPricingCardItem.tsx

import React from "react";
import { InfoSubscription } from "../../../../types";
import { useSubscriptionStore } from "../../../../store/useSubscriptionStore";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import "./Horizontal.scss";
import { defaultPieces } from "../../../../assets/svg/pieces";

interface HorizontalPricingCardItemProps {
  data: InfoSubscription;
  isBuy: boolean;
}

const pieces = defaultPieces({ width: "70px", height: "70px" });

// Fonction pour convertir le code de devise en symbole
const getCurrencySymbol = (currencyCode: string): string => {
  switch (currencyCode.toUpperCase()) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    case "JPY":
      return "¥";
    case "CAD":
      return "$";
    case "AUD":
      return "$";
    default:
      return currencyCode; // Retourner le code si aucun symbole n'est trouvé
  }
};

// Fonction pour convertir l'intervalle en texte approprié
const getIntervalText = (interval: string): string => {
  if (interval.includes("mois")) {
    const match = interval.match(/(\d+)\s*mois/);
    if (match && parseInt(match[1], 10) === 6) {
      return "Semestriel";
    }
    return "Mensuel";
  } else if (interval.includes("an")) {
    return "Annuel";
  }
  return interval; // Si l'intervalle n'est pas reconnu
};

const HorizontalPricingCardItem: React.FC<HorizontalPricingCardItemProps> = ({
  data,
  isBuy,
}) => {
  const { selectSubscription } = useSubscriptionStore();

  // Gestion des fonctionnalités limitées à 3
  const maxFeaturesToShow = 3;
  const displayedFeatures = data.features.slice(0, maxFeaturesToShow);
  const remainingFeaturesCount = data.features.length - maxFeaturesToShow;

  return (
    <div className="pricing-card-horizontal">
      {data.discount > 0 && (
        <div className="discount-ribbon">
          <span>-{data.discount.toFixed(0)}%</span>
        </div>
      )}
      <div className="card-content">
        <div className="card-left">
          <div className="card-header">
            <div className="icon">{pieces.wK}</div>
            <h2>{getIntervalText(data.interval)}</h2>
          </div>
          {/* Section du prix */}
          <div className="card-price">
            {data.discount > 0 && data.originalPrice ? (
              <>
                {data.endDate && (
                  <div className="timerPrincipal">
                    <FlipClockCountdown
                      to={new Date(data.endDate || "").getTime()}
                      labelStyle={{
                        fontSize: 0,
                        color: "hsl(35, 65%, 75%)",
                      }}
                      separatorStyle={{
                        color: "hsl(0, 0%, 100%)",
                        size: "6px",
                      }}
                      digitBlockStyle={{
                        width: 18,
                        height: 27,
                        fontSize: 18,
                        color: "hsl(0, 0%, 100%)",
                      }}
                      labels={["D", "H", "M", "S"]}
                      showLabels={false}
                      duration={0.5}
                      renderMap={[false, true, true, true]}
                    />
                  </div>
                )}
                <div className="original-discounted">
                  <span className="original-price" style={{ textDecoration: "line-through" }}>
                    {parseFloat('' + data.originalPrice).toFixed(2)} {getCurrencySymbol(data.currency)}
                  </span>
                  <h4>
                    <span className="discounted-price">
                      {parseFloat(data.price).toFixed(2)} {getCurrencySymbol(data.currency)}
                      <span className="annualPlan">/{getIntervalText(data.interval)}</span>
                    </span>
                  </h4>
                </div>
              </>
            ) : (
              <h4>
                {parseFloat(data.price).toFixed(2)} {getCurrencySymbol(data.currency)}{" "}
                <span className="annualPlan">/{getIntervalText(data.interval)}</span>
              </h4>
            )}
          </div>
        </div>
        <div className="card-right">
          <div className="card-features">
            <ul>
              {displayedFeatures.map((feature, index) => (
                <li key={index}>
                  <i className="fa fa-check" aria-hidden="true"></i>
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          {isBuy && (
            <div className="paySubscription-button">
              <button onClick={() => selectSubscription(data)}>Buy Now</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalPricingCardItem;
