import { defaultPieces } from "./assets/svg/pieces";
import { CommentLanguage, OpeningDataHomePage, OpeningMove } from "./types";

const pieces = defaultPieces({ width: "70px", height: "70px" });
// Objet OpeningMove vide avec toutes les clés initialisées
const emptyOpeningMove: OpeningMove = {
  evaluation: "", // Chaîne vide pour l'évaluation
  arrows: [], // Tableau vide pour les flèches
  circles: [], // Tableau vide pour les cercles
  history: [], // Tableau vide pour l'historique
  san: "", // Chaîne vide pour SAN (notation algébrique standard)
  fen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
  path: [], // Tableau vide pour le chemin (path)
  blockEntrainement: false,
  children: {}, // Objet vide pour les enfants (prochaines possibilités)
};

const infoOpeningData: OpeningDataHomePage = {
  _id: "",
  title: "",
  creator: "",
  level: 1,
  color: "w",
  nbrOfPath: 0,
  description: "",
  history: [],
};

/* const infoSubscription: InfoSubscription[] = [
  {
    icon: pieces.wP, // Pièce d'échecs (Pion blanc)
    title: "ROAD TO FM",
    price: "9$", // Prix par mois
    annualPlan: "/mois",
    discount: 15, // Réduction de 15%
    endDate: new Date("2025-12-31"), // Date de fin de l'offre
    features: [
      "Accès illimité aux cours.",
      "Évaluations illimitées ",
      "Entraînements illimités ",
    ],
    idStripe: "price_1QFspQJM4WzEaOO4stFdztlK",
  },
  {
    icon: pieces.wN, // Pièce d'échecs (Cavalier blanc)
    title: "ROAD TO MI",
    price: "49$", // Prix pour 6 mois
    annualPlan: "/6 mois",
    discount: 15, // Réduction de 15%
    endDate: new Date("2025-12-31"),
    features: [
      "Accès illimité aux cours.",
      "Évaluations illimitées",
      "Entraînements illimités",
    ],
    idStripe: "price_1QFstWJM4WzEaOO42Vh1nSQM",
  },
  {
    icon: pieces.wK, // Pièce d'échecs (Roi blanc)
    title: "ROAD TO GM",
    price: "89$", // Prix par an
    annualPlan: "/an",
    discount: 25, // Réduction de 25%
    endDate: new Date("2025-12-31"),
    features: [
      "Accès illimité aux cours.",
      "Évaluations illimitées",
      "Entraînements illimités",
    ],
    idStripe: "price_1QFsv1JM4WzEaOO4dLx9pNqE",
  },
]; */

export const config: {
  nbrMoveForConnect: number;
  nbrMoveForPremium: number;
  language: keyof CommentLanguage;
  localStorageCreateOpening: "opening-create-storage";
  emptyOpeningMove: OpeningMove;
  emptyInfoOpeningData: OpeningDataHomePage;
  nbrCompletedVariante: number;
  nbrMemoryPosition: number;
} = {
  nbrMoveForConnect: 10,
  nbrMoveForPremium: 50,
  language: "fr", // ou "fr"
  localStorageCreateOpening: "opening-create-storage",
  emptyOpeningMove: emptyOpeningMove,
  emptyInfoOpeningData: infoOpeningData,
  nbrCompletedVariante: 2,
  nbrMemoryPosition: 2,
};
