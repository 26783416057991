import { useState } from "react";
import { useSearchOpening } from "../../store/useSearchOpening";

import ColorSelectorBoard from "../commun/ColorSelectorBoard/ColorSelectorBoard";
import LevelSelector from "../commun/LevelSelector/LevelSelector";
import "./FilterOptions.scss";

const FilterOptions = () => {
  const { setFilterColor, setFilterCategory, setFilterLevel } =
    useSearchOpening();

  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedLevel, setSelectedLevel] = useState<1 | 2 | 3 | null>(null);

  const handleCategoryClick = (category: string) => {
    if (selectedCategory === category) {
      setSelectedCategory(null);
      setFilterCategory(null);
    } else {
      setSelectedCategory(category);
      setFilterCategory(category);
    }
  };

  const handleColorSelect = (color: string | null) => {
    setSelectedColor(color);
    setFilterColor(color);
  };

  const handleLevelChange = (level: 1 | 2 | 3 | null) => {
    setSelectedLevel(level);
    setFilterLevel(level);
  };

  return (
    <div className="filter-options">
      {/* Composant ColorSelector */}
      <ColorSelectorBoard
        selectedColor={selectedColor}
        onColorSelect={handleColorSelect}
      />

      {/* Composant LevelSelector */}
      <LevelSelector
        selectedLevel={selectedLevel}
        onLevelChange={handleLevelChange}
      />

      {/* Boutons de catégorie */}
      <div className="filter-options_category">
        <button
          onClick={() => handleCategoryClick("Populaires")}
          className={selectedCategory === "Populaires" ? "active" : ""}
        >
          Populaires
        </button>
        <button
          onClick={() => handleCategoryClick("Gambit")}
          className={selectedCategory === "Gambit" ? "active" : ""}
        >
          Gambit
        </button>
        <button
          onClick={() => handleCategoryClick("Classiques")}
          className={selectedCategory === "Classiques" ? "active" : ""}
        >
          Classiques
        </button>
      </div>
    </div>
  );
};

export default FilterOptions;
