import { create } from "zustand";
import { config } from "../config";
import { mockOpeningEmptyData } from "../mock/mockOpeninEmptyData";
import {
  Language,
  OpeningDataHomePage,
  OpeningDataSendServeur,
  OpeningMove,
  TreeNode,
  TreeOpeningData,
} from "../types";
import { GameChess } from "../utils/GameChess";
import { OpeningManager } from "../utils/OpeningManager";
import TreeManager from "../utils/TreeManager";

// Interface pour les propriétés d'état
interface CreateOpeningState {
  openingManager: OpeningManager;
  gameChess: GameChess;
  pathIndex: number;
  nbrOfPath: number;
  treeOpeningData: TreeOpeningData;
  infoOpeningData: OpeningDataHomePage;
  currentMove: OpeningMove;
  comments: { [fen: string]: { [lang: string]: string } };
  colorArrow: string;

  selectedNode: TreeNode | null;
}

// Interface pour les méthodes (actions)
interface CreateOpeningActions {
  resetStore: () => void;
  loadData: (commentOpeningTree: any, infoOpeningData: any) => void;
  loadDataFromLocalStorage: () => void;
  setOpeningTree: (newTreeOpeningData: TreeOpeningData) => void;
  updateInfoOpeningData: (updatedData: OpeningDataSendServeur) => void;
  setInfoOpeningData: (infoOpening: OpeningDataHomePage) => void;
  setComment: (comments: { [fen: string]: { [lang: string]: string } }) => void;
  getComment: (fen: string, lang: Language) => string | null;
  updateComment: (newComment: string, lang: string) => void;
  setColorArrow: (colorArrow: string) => void;

  addMove: (moveData: OpeningMove) => OpeningMove;
  clearMove: (move: OpeningMove) => OpeningMove;
  updateMove: (moveData: OpeningMove) => boolean;
  updateBlockEntrainement: (
    move: OpeningMove,
    blockEntrainement: boolean
  ) => void;
  deleteMove: (move: OpeningMove) => null | OpeningMove;
  getOpeningData: () => TreeOpeningData;
  getOpeningTreeConverted: () => TreeNode | null;
  setCurrentMove: (move: OpeningMove) => void;
  loadMove: (moveDetail: OpeningMove) => void;
  resetGame: () => void;
  setSelectedNode: (node: TreeNode | null) => void;
  setpath: (index: number) => void;
  addMultiPath: (
    nodeStart: TreeNode,
    nodeFinal: TreeNode
  ) => { success: boolean; message: string };
  decrementCurrentPageIndex: () => void;
  checkAllMovesHavePath: () => boolean;
}

// Type combinant les propriétés d'état et les méthodes
type CreateOpeningStore = CreateOpeningState & CreateOpeningActions;

// Fonction pour obtenir la clé de stockage basée sur l'URL actuelle
const getStorageKey = (): string =>
  `${window.location.pathname}-${config.localStorageCreateOpening}`;

// Fonction pour charger l'état depuis le localStorage
const loadStateFromLocalStorage = (): Partial<CreateOpeningState> | null => {
  const key = getStorageKey();
  console.log(key);
  const savedState = localStorage.getItem(key);
  return savedState ? JSON.parse(savedState) : null;
};

// Charger l'état initial du store
const savedState = loadStateFromLocalStorage();

// Créer les instances de OpeningManager et GameChess en fonction de l'état chargé
const openingManager = new OpeningManager(
  savedState?.treeOpeningData || mockOpeningEmptyData
);

const gameChess = new GameChess();
if (savedState?.currentMove?.fen) {
  gameChess.loadFromFEN(savedState.currentMove.fen);
}

// Définir l'état initial du store
const initialState: CreateOpeningState = {
  openingManager,
  gameChess,
  pathIndex: savedState?.pathIndex || 1,
  nbrOfPath: savedState?.nbrOfPath || 1,
  treeOpeningData: savedState?.treeOpeningData || mockOpeningEmptyData,
  infoOpeningData: savedState?.infoOpeningData || config.emptyInfoOpeningData,
  currentMove: savedState?.currentMove || config.emptyOpeningMove,
  comments: savedState?.comments || {},
  colorArrow: savedState?.colorArrow || "#FF0000",

  selectedNode: null,
};

const useCreateOpeningStore = create<CreateOpeningStore>((set, get) => ({
  ...initialState,

  // Actions du store
  // Action pour charger les données depuis `localStorage`
  loadDataFromLocalStorage: () => {
    const savedState = loadStateFromLocalStorage();
    if (savedState) {
      set({
        openingManager: new OpeningManager(savedState.treeOpeningData!),
        gameChess: new GameChess(),
        pathIndex: savedState.pathIndex || 1,
        nbrOfPath: savedState.nbrOfPath || 1,
        treeOpeningData: savedState.treeOpeningData || mockOpeningEmptyData,
        infoOpeningData:
          savedState.infoOpeningData || config.emptyInfoOpeningData,
        currentMove: savedState.currentMove || config.emptyOpeningMove,
        comments: savedState.comments || {},
        colorArrow: savedState.colorArrow || "#FF0000",

        selectedNode: savedState.selectedNode || null,
      });
    } else {
      console.warn("Aucune donnée trouvée dans le localStorage.");
    }
  },

  loadData: (commentOpeningTree: any, infoOpeningData: OpeningDataHomePage) => {
    const commentOpeningTreeParse = JSON.parse(commentOpeningTree);
    get().setOpeningTree(commentOpeningTreeParse.openingTree);
    get().setComment(commentOpeningTreeParse.comments);
    get().setInfoOpeningData(infoOpeningData);

    get().loadMove(get().openingManager.getMoveByPage(0)!);
  },

  resetStore: () => {
    const newState = {
      ...initialState,
      openingManager: new OpeningManager(mockOpeningEmptyData),
      gameChess: new GameChess(),
      pathIndex: 1,
      nbrOfPath: 1,
      infoOpeningData: config.emptyInfoOpeningData,
      treeOpeningData: mockOpeningEmptyData,
      currentMove: config.emptyOpeningMove,
      comments: {},
      colorArrow: "#FF0000",

      selectedNode: null,
    };
    set(newState);
    saveStateToLocalStorage(newState);
  },

  setOpeningTree: (newTreeOpeningData: TreeOpeningData) => {
    const openingManager = new OpeningManager(newTreeOpeningData);
    set({ openingManager, treeOpeningData: newTreeOpeningData });
    saveStateToLocalStorage(get());
  },

  updateInfoOpeningData: (updatedData: OpeningDataSendServeur) => {
    const newInfoOpeningData = {
      ...get().infoOpeningData,
      ...updatedData,
    };
    set({ infoOpeningData: newInfoOpeningData });
    saveStateToLocalStorage(get());
  },

  setInfoOpeningData: (infoOpening: OpeningDataHomePage) => {
    set({ infoOpeningData: infoOpening });
    saveStateToLocalStorage(get());
  },

  setComment: (comments: { [fen: string]: { [lang: string]: string } }) => {
    set({ comments });
    saveStateToLocalStorage(get());
  },

  getComment: (fen: string, lang: Language): string | null => {
    const commentsForFEN = get().comments[fen];
    if (!commentsForFEN) {
      return null;
    }
    return commentsForFEN[lang] || null;
  },

  updateComment: (newComment: string, lang: string) => {
    const fen = get().gameChess.getBoardPosition();
    const currentComments = get().comments[fen] || {};

    const updatedComments = {
      ...currentComments,
      [lang]: newComment,
    };

    const newComments = {
      ...get().comments,
      [fen]: updatedComments,
    };

    set({ comments: newComments });
    saveStateToLocalStorage(get());
  },

  setColorArrow: (colorArrow: string) => {
    set({ colorArrow });
    saveStateToLocalStorage(get());
  },

  addMove: (moveData: OpeningMove): OpeningMove => {
    const openingManager = get().openingManager;
    const isExistingMove = openingManager.getMoveDetails(moveData.history);

    if (!isExistingMove) {
      const result = openingManager.addMove(moveData);

      if (result) {
        get().gameChess.loadFromFEN(moveData.fen);
        set({ currentMove: moveData });
        set({ treeOpeningData: openingManager.getTreeOpeningData() });
        saveStateToLocalStorage(get());
      }
      return result;
    }

    get().gameChess.loadFromFEN(moveData.fen);
    set({ currentMove: isExistingMove });
    saveStateToLocalStorage(get());
    return isExistingMove;
  },

  clearMove: (move: OpeningMove): OpeningMove => {
    const clearMove: OpeningMove = {
      ...move,
      evaluation: "good",
      arrows: [],
      circles: [],
    };

    const openingManager = get().openingManager;
    const isGood = openingManager.updateMove(clearMove);

    if (isGood) {
      set({ currentMove: clearMove });
      saveStateToLocalStorage(get());
    }

    return clearMove;
  },

  updateMove: (moveData: OpeningMove): boolean => {
    const openingManager = get().openingManager;
    const result = openingManager.updateMove(moveData);
    if (result) {
      set({ currentMove: moveData });
      saveStateToLocalStorage(get());
    }
    return result;
  },

  updateBlockEntrainement: (move: OpeningMove, blockEntrainement: boolean) => {
    const openingManager = get().openingManager;
    const newMove = {
      ...move,
      blockEntrainement,
    };

    openingManager.updateMove(newMove);
    set({ treeOpeningData: openingManager.getTreeOpeningData() });
    saveStateToLocalStorage(get());
  },

  deleteMove: (move: OpeningMove): null | OpeningMove => {
    const openingManager = get().openingManager;
    const newMove = openingManager.deleteMove(move);

    if (newMove) {
      get().loadMove(newMove);
      set({ treeOpeningData: openingManager.getTreeOpeningData() });
      saveStateToLocalStorage(get());
    }
    return newMove;
  },

  getOpeningData: (): TreeOpeningData => {
    const openingManager = get().openingManager;
    return {
      tree: {
        ...openingManager.getOpeningTree(),
      },
    };
  },

  getOpeningTreeConverted: (): TreeNode | null => {
    const tree = get().openingManager.getOpeningTree();
    return TreeManager.convertToTreeData(tree);
  },

  setCurrentMove: (move: OpeningMove) => {
    set({ currentMove: move });
    saveStateToLocalStorage(get());
  },

  loadMove: (moveDetail: OpeningMove) => {
    get().gameChess.loadPosition(moveDetail.history);
    set({ currentMove: moveDetail });
    saveStateToLocalStorage(get());
  },

  resetGame: () => {
    set({
      gameChess: new GameChess(),
    });
    saveStateToLocalStorage(get());
  },

  setSelectedNode: (node: TreeNode | null) => {
    set({ selectedNode: node });
    saveStateToLocalStorage(get());
  },

  setpath: (path: number) => {
    set({ pathIndex: path });
    saveStateToLocalStorage(get());
  },

  addMultiPath: (
    nodeStart: TreeNode,
    nodeFinal: TreeNode
  ): { success: boolean; message: string } => {
    const state = get();

    if (!nodeStart?.move?.history || !nodeFinal?.move?.history) {
      return {
        success: false,
        message: "History is missing or undefined in one or both nodes.",
      };
    }

    const movesPathShort: OpeningMove[] =
      state.openingManager.findShortestPathBetweenMoves(
        nodeStart.move.history,
        nodeFinal.move.history
      );

    if (!(movesPathShort[0]?.path?.length >= 0)) {
      return {
        success: false,
        message: "First path is empty, action halted.",
      };
    }

    const areOtherPathsEmpty = movesPathShort
      .slice(1)
      .every((move) => move.path.length === 0);
    if (!areOtherPathsEmpty) {
      return {
        success: false,
        message: "Not all other paths are empty, action halted.",
      };
    }

    if (!nodeFinal?.move?.children) {
      return {
        success: false,
        message: "NodeFinal is undefined or missing children property.",
      };
    }

    const childKeys = Object.keys(nodeFinal.move.children);
    if (!(childKeys.length === 0 || childKeys.length >= 2)) {
      return {
        success: false,
        message: "NodeFinal has exactly one child, processing halted.",
      };
    }

    let nbrOfPath = get().nbrOfPath;

    for (const move of movesPathShort) {
      if (
        move.path[move.path.length - 1] + 1 !== nbrOfPath &&
        !move.path.includes(0)
      ) {
        const moveAddPath = {
          ...move,
          path: [...move.path, nbrOfPath],
        };
        nbrOfPath++;
        state.updateMove(moveAddPath);
      }
    }

    const newInfo = {
      ...state.infoOpeningData,
      nbrOfPath: nbrOfPath,
    };
    state.updateInfoOpeningData(newInfo);
    set({ nbrOfPath: nbrOfPath });

    saveStateToLocalStorage(get());

    return {
      success: true,
      message: "Valid path and children conditions met.",
    };
  },

  decrementCurrentPageIndex: () => {
    const state = get();
    const currentPathIndex = state.pathIndex;

    if (currentPathIndex > 1) {
      const newPathIndex = currentPathIndex - 1;
      const move = state.openingManager.getMoveByPage(newPathIndex);
      if (move) {
        const moveDeleteLastPath = {
          ...move,
          path: move.path.filter((item) => item !== newPathIndex),
        };

        state.updateMove(moveDeleteLastPath);
        state.setpath(newPathIndex);
        saveStateToLocalStorage(get());
      }
    } else {
      console.warn(
        "Le pathIndex est déjà à 1, aucune décrémentation effectuée."
      );
    }
  },

  checkAllMovesHavePath: (): boolean => {
    return get().openingManager.checkAllMovesHavePath();
  },
}));

// Fonction pour sauvegarder l'état dans le localStorage
const saveStateToLocalStorage = (state: CreateOpeningState) => {
  const key = getStorageKey();
  // Exclure les objets non sérialisables
  const { openingManager, gameChess, ...serializableState } = state;
  localStorage.setItem(key, JSON.stringify(serializableState));
};

// Souscrire aux changements du store pour sauvegarder automatiquement
useCreateOpeningStore.subscribe((state) => {
  saveStateToLocalStorage(state);
});

// Écouter les changements de navigation pour charger les données appropriées
window.addEventListener("popstate", () => {
  const savedState = loadStateFromLocalStorage();
  if (savedState) {
    const openingManager = new OpeningManager(
      savedState.treeOpeningData || mockOpeningEmptyData
    );
    const gameChess = new GameChess();
    if (savedState.currentMove?.fen) {
      gameChess.loadFromFEN(savedState.currentMove.fen);
    }

    useCreateOpeningStore.setState({
      ...savedState,
      openingManager,
      gameChess,
    } as CreateOpeningState);
  } else {
    // Si aucune donnée n'est trouvée, réinitialiser le store
    useCreateOpeningStore.getState().resetStore();
  }
});

export default useCreateOpeningStore;
