// FormPayement.tsx

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaCalendarAlt, FaCreditCard, FaGift, FaLock } from "react-icons/fa";

import {
  SiAmericanexpress,
  SiMastercard,
  SiStripe,
  SiVisa,
} from "react-icons/si";
import { BeatLoader } from "react-spinners";
import { useSubscriptionStore } from "../../../store/useSubscriptionStore";
import PricingCard from "../../commun/PricingCard/PricingCard";
import "./FormPayement.scss";
import SuccesPayement from "./SuccesPayement/SuccesPayement";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import RecapPremium from "../../Premium/RecapPremium/RecapPremium";

const API_URL = process.env.REACT_APP_API_URL || "";

const PaymentForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    selectedSubscription,
    selectSubscription,
    setLoadingPayment,
    applyDiscount, // Importer la méthode du store
  } = useSubscriptionStore();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isVerifyingPromo, setIsVerifyingPromo] = useState(false); // État pour gérer l'animation de chargement

  const handlePromoCodeChange = async (value: string) => {
    setPromoCode(value);

    if (value.length === 6) {
      setIsVerifyingPromo(true); // Démarre l'animation de chargement
      try {
        const response = await axios.post(
          `${API_URL}/api/verify-promo`,
          {
            code: value,
            priceId: selectedSubscription?.priceID,
          },
          {
            withCredentials: true,
          }
        );
        if (response.data.discountPercent && selectedSubscription !== null) {
          const discountPercent = response.data.discountPercent;
          const newPrice = response.data.previewPrice;

          // Appliquer la réduction via la méthode du store
          applyDiscount(newPrice);

          toast.success(
            `Code promo appliqué avec succès ! ${discountPercent}% de réduction`,
            {
              autoClose: 4000,
              position: "top-right",
              theme: "colored",
            }
          );
        } else {
          toast.error("Code promo invalide ou expiré", {
            autoClose: 4000,
            position: "top-right",
            theme: "colored",
          });
        }
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.error, {
            autoClose: 4000,
            position: "top-right",
            theme: "colored",
          });
        } else {
          console.error("Erreur lors de la vérification du code promo :", error);
          toast.error("Une erreur est survenue. Veuillez réessayer plus tard.", {
            autoClose: 4000,
            position: "top-right",
            theme: "colored",
          });
        }
      } finally {
        setIsVerifyingPromo(false); // Arrête l'animation de chargement après le retour de l'API
      }
    }
  };

  const elementStyles = {
    base: {
      color: "#ffffff",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
    },
  };

  const elementOptions = {
    style: elementStyles,
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      console.error("CardNumber Element not found");
      return;
    }

    setLoading(true);
    setErrorMessage("");

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      if (error || !paymentMethod) {
        console.error("Error creating payment method:", error);
        setErrorMessage("Le paiement a échoué. Veuillez réessayer.");
        setLoading(false);
        return;
      }

      setLoadingPayment(true);
      const response = await axios.post(`${API_URL}/api/create-subscription`, {
        paymentMethodId: paymentMethod.id,
        priceId: selectedSubscription?.priceID,
        promoCode: promoCode,
      });

      if (response.data.subscriptionDate) {
        setLoadingPayment(false);
        setPaymentSuccess(true);
      }
    } catch (error) {
      console.error("Erreur lors de la création de l'abonnement:", error);
      setErrorMessage("Une erreur est survenue. Veuillez réessayer plus tard.");
    } finally {
      setLoading(false);
    }
  };

  const [layout, setLayout] = useState<"vertical" | "horizontal">("vertical");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setLayout("horizontal");
      } else {
        setLayout("vertical");
      }
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Call handler right away to set the initial layout
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="payment-form-container">
      {paymentSuccess ? (
        <RecapPremium />
      ) : (
        <div className="payment-form-wrapper">
          {selectedSubscription && (
            <div className="payment-form__pricing-card">
              <PricingCard
                data={selectedSubscription}
                layout={layout}
                isBuy={false}
              />
            </div>
          )}

          <form className="payment-form" onSubmit={handleSubmit}>
            <div className="payment-form__field">
              <label>
                <FaCreditCard className="payment-form__icon" /> Numéro de carte
              </label>
              <CardNumberElement options={elementOptions} />
            </div>

            <div className="payment-form__field">
              <label>
                <FaCalendarAlt className="payment-form__icon" /> Date
                d'expiration
              </label>
              <CardExpiryElement options={elementOptions} />
            </div>

            <div className="payment-form__field">
              <label>
                <FaLock className="payment-form__icon" /> Code CVC
              </label>
              <CardCvcElement options={elementOptions} />
            </div>

            <div className="payment-form__field">
              <label>
                <FaGift className="payment-form__icon" /> Code Promo
              </label>
              <div className="promo-code-input-wrapper" style={{ position: "relative" }}>
                <input
                  type="text"
                  value={promoCode}
                  onChange={(e) => handlePromoCodeChange(e.target.value.toUpperCase())}
                  maxLength={6}
                  placeholder="XXXXXX"
                  className="promo-code-input"
                  style={{
                    textTransform: "uppercase",
                    letterSpacing: "0.5em",
                    paddingRight: "2.5em",
                  }}
                />
                {isVerifyingPromo && (
                  <FaSpinner
                    className="promo-code-loader"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#ffffff",
                      animation: "spin 1s linear infinite",
                    }}
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="payment-form__pay-button"
              disabled={!stripe || loading}
            >
              {loading ? (
                <div className="payment-button-content">
                  <BeatLoader color="#ffffff" size={10} />
                </div>
              ) : (
                <div className="payment-button-content">
                  <span>Payer votre abonnement</span>
                  <FaLock className="payment-form__icon" />
                </div>
              )}
            </button>

            {errorMessage && (
              <p className="payment-form__error-message">{errorMessage}</p>
            )}

            <p className="payment-form__security-message">
              <FaLock className="payment-form__icon" />
              Paiement 100% sécurisé via Stripe
            </p>
            <div className="payment-form__payment-logos">
              <SiVisa className="payment-form__payment-logo" />
              <SiMastercard className="payment-form__payment-logo" />
              <SiAmericanexpress className="payment-form__payment-logo" />
              <SiStripe className="payment-form__payment-logo" />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
