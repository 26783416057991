import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import React from "react";
import { InfoSubscription } from "../../../types";
import Horizontal from "./Horizontal/Horizontal";
import Vertical from "./Vertical/Vertical";

interface PricingCardProps {
  data: InfoSubscription;
  layout?: "vertical" | "horizontal";
  isBuy?: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({
  data,
  layout = "vertical",
  isBuy = true,
}) => {
  return layout === "vertical" ? (
    <Vertical data={data} isBuy={isBuy} />
  ) : (
    <Horizontal data={data} isBuy={isBuy} />
  );
};

export default PricingCard;
