import Grid from "@mui/material/Grid";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import InfoCreateur from "../commun/InfoGeneralOpenning/InfoCreateur/InfoCreateur";
import Panel from "../commun/Panel/Panel";
import ProgressBarComponent from "../commun/ProgressBar/ProgressBarComponent";
import { OpeningVariantTree } from "../TreeOpening/OpeningVariantTree";
import EvaluationDetails from "./EvaluationDetails/EvaluationDetails";
import MoveDetails from "./MoveDetails/MoveDetails";
import SessionStats from "./SessionStats/SessionStats";
import "./TreeAndDetails.scss";
import logo from "../../assets/logo.png";
import { evaluation } from "../../assets/svg/evaluation";
import { Evaluation } from "../../types";

// Import Material UI
import Container from "@mui/material/Container";
import { Grid2 } from "@mui/material";
import { defaultPieces } from "../../assets/svg/pieces";
import { getPieceFromMove } from "../../utils/utils";

export default function TreeAndDetails() {
  const { mode, currentPage, infoOpeningData } = useLessonOpeningStore();
  const { currentMove } = useLessonOpeningStore();
  const pieces = defaultPieces({ width: "30px", height: "30px" });
  const piece = getPieceFromMove(currentMove.san, currentMove.history);

  return (
    <>
      <div className="treeAndDetails">
        {/* Container pour InfoCreateur */}
        <Container className="rounded-container"
          sx={{
            height: "15%",
            marginBottom: "5%",
          }}
        >
          <div>
            <InfoCreateur />
          </div>
        </Container>


        {/* Container pour OpeningVariantTree */}
        <Container className="rounded-container"
          sx={{
            height: "30%",
            marginBottom: "5%",
          }}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={4}>
              <div className="tree-and-details-logo-container">
                <img
                  src={logo}
                  alt="logo"
                />
              </div>
            </Grid2>
            <Grid2 size={4} className="wrapper_title_move">
              <div className="wrapper_title_move">
                {evaluation &&
                  evaluation[currentMove.evaluation as keyof Evaluation] && (
                    <div className="bulleText-wrapper_title_evaluation">
                      {evaluation[currentMove.evaluation as keyof Evaluation]}
                    </div>
                  )}
                {currentMove.history.length > 0 && (
                  <>
                    {piece && pieces[piece] && (
                      <div className="piece-icon">{pieces[piece]}</div>
                    )}
                    <strong className="wrapper_title_move">
                      <h3>
                        {currentMove.history.length + "."}
                      </h3>
                    </strong>
                    <strong className="wrapper_title_move">
                      <h3>
                        {currentMove.san}
                      </h3>
                    </strong>
                  </>
                )}
              </div>
            </Grid2>
            <Grid2 size={4}>
              <div className={`treeAndDetails_tree ${mode === "cours" ? "" : "blur"}`}>
                <OpeningVariantTree />
              </div>
            </Grid2>
          </Grid2>

        </Container>

        {/* Container pour treeAndDetails_details */}
        <Container className="rounded-container"
          sx={{
            height: "44%",
          }}
        >
          <div className="treeAndDetails_details">
            {mode === "cours" && (
              <>
                <MoveDetails />
              </>
            )}
            {mode === "graduation" && <EvaluationDetails />}
            {mode === "entrainement" && <SessionStats />}


            <Panel />
          </div>
        </Container>
      </div>

      <div className="treeAndDetails-mobile">
        {mode === "cours" && (
          <>
            <MoveDetails />
          </>
        )}
        {mode === "graduation" && <EvaluationDetails />}
        {mode === "entrainement" && <SessionStats />}
        <Panel />
        {mode === "cours" && (
          <ProgressBarComponent
            percentage={(currentPage / infoOpeningData.nbrOfPath) * 100}
          />
        )}
      </div>
    </>
  );
}
