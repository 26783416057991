import React, { useEffect, useRef, useState } from "react";
import { Chessboard } from "react-chessboard";
import { useSettingChessboardStore } from "../../../store/useSettingChessboardStore";
import "./ChessboardSizeHandler.scss";

interface ChessboardSizeHandlerProps {
  position: string;
  customArrows: any;
  arePiecesDraggable: boolean;
  onPieceDrop?: (sourceSquare: string, targetSquare: string) => boolean;
  onSquareClick: (square: string) => void;
  boardOrientation: "white" | "black";
  resizable?: boolean;
  createArrow?: boolean;
  isMode?: boolean;
  customSquareStyles?: any;
}

const ChessboardSizeHandler: React.FC<ChessboardSizeHandlerProps> = ({
  position,
  customArrows,
  arePiecesDraggable,
  onPieceDrop,
  onSquareClick,
  boardOrientation,
  customSquareStyles,
  resizable = true,
  isMode = false,
  createArrow = true,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [boardSize, setBoardSize] = useState<number>(400); // Taille par défaut initiale

  const { setSize, mode } = useSettingChessboardStore();

  // Fonction pour mettre à jour la taille de l'échiquier lorsque `isMode` est actif
  const updateBoardSizeWithMode = () => {
    if (
      containerRef.current &&
      containerRef.current.parentElement?.parentElement
    ) {
      const grandParent = containerRef.current.parentElement
        .parentElement as HTMLElement;
      const grandParentStyles = getComputedStyle(grandParent);

      // Dimensions totales du grand-parent
      const grandParentWidth = grandParent.clientWidth;
      const grandParentHeight = grandParent.clientHeight;

      // Paddings du grand-parent
      const grandParentPaddingLeft = parseFloat(grandParentStyles.paddingLeft);
      const grandParentPaddingRight = parseFloat(
        grandParentStyles.paddingRight
      );
      const grandParentPaddingTop = parseFloat(grandParentStyles.paddingTop);
      const grandParentPaddingBottom = parseFloat(
        grandParentStyles.paddingBottom
      );

      // Espace disponible dans le grand-parent (sans les paddings)
      const availableWidth =
        grandParentWidth - grandParentPaddingLeft - grandParentPaddingRight;
      const availableHeight =
        grandParentHeight - grandParentPaddingTop - grandParentPaddingBottom;

      // Tailles des éléments frères (siblings) du grand-parent
      let totalSiblingWidth = 0;
      let totalSiblingHeight = 0;

      const siblings = Array.from(grandParent.children).filter(
        (child) => child !== containerRef?.current?.parentElement
      ) as HTMLElement[];

      siblings.forEach((sibling) => {
        const siblingStyles = getComputedStyle(sibling);

        // Marges de l'élément sibling
        const siblingMarginLeft = parseFloat(siblingStyles.marginLeft);
        const siblingMarginRight = parseFloat(siblingStyles.marginRight);
        const siblingMarginTop = parseFloat(siblingStyles.marginTop);
        const siblingMarginBottom = parseFloat(siblingStyles.marginBottom);

        // Hauteur et largeur totales de l'élément sibling (incluant marges)
        const siblingTotalWidth =
          sibling.offsetWidth + siblingMarginLeft + siblingMarginRight;
        const siblingTotalHeight =
          sibling.offsetHeight + siblingMarginTop + siblingMarginBottom;

        // Accumuler les tailles selon la disposition
        if (mode === "horizontal") totalSiblingWidth += siblingTotalWidth;
        else if (mode === "vertical") totalSiblingHeight += siblingTotalHeight;
      });

      // Espace disponible pour l'échiquier
      const remainingWidth = availableWidth - totalSiblingWidth;
      const remainingHeight = availableHeight - totalSiblingHeight;

      // Calculer la taille maximale possible pour l'échiquier
      const size = Math.min(remainingWidth, remainingHeight);
      setBoardSize(size > 0 ? size : 0); // S'assurer que la taille n'est pas négative

      setSize(size);
    }
  };

  // Fonction pour mettre à jour la taille de l'échiquier lorsque `isMode` est désactivé
  const updateBoardSizeWithoutMode = () => {
    if (containerRef.current) {
      const { clientWidth, clientHeight } = containerRef.current;
      setBoardSize(Math.min(clientWidth, clientHeight));
    }
  };

  useEffect(() => {
    const updateBoardSize = isMode
      ? updateBoardSizeWithMode
      : updateBoardSizeWithoutMode;

    // Calcul initial de la taille
    updateBoardSize();

    // Créer un ResizeObserver pour surveiller les changements de taille
    const resizeObserver = new ResizeObserver(() => {
      setTimeout(updateBoardSize, 100); // Ajuster le délai si nécessaire
    });

    if (
      containerRef.current &&
      (isMode
        ? containerRef.current.parentElement?.parentElement
        : containerRef.current)
    ) {
      const target = isMode
        ? containerRef.current.parentElement?.parentElement
        : containerRef.current;
      if (target) resizeObserver.observe(target);
    }

    // Nettoyer l'observateur lors du démontage du composant
    return () => {
      resizeObserver.disconnect();
    };
  }, [isMode, mode, setSize]);

  return (
    <div ref={containerRef} className="chessboard-container">
      <Chessboard
        position={position}
        boardWidth={boardSize}
        customArrows={customArrows}
        arePiecesDraggable={arePiecesDraggable}
        onPieceDrop={onPieceDrop}
        onSquareClick={onSquareClick}
        boardOrientation={boardOrientation}
        areArrowsAllowed={createArrow}
        customSquareStyles={customSquareStyles}
      />
    </div>
  );
};

export default ChessboardSizeHandler;
