import React from "react";
import { OpeningMove } from "../../../types";
import CustomNode from "../CustomNode/CustomNode";

export function renderCustomNode(
  { nodeDatum }: { nodeDatum: any },
  chessboardPreviewRef: React.RefObject<any> | undefined,
  currentMove?: OpeningMove | undefined, // Autoriser undefined ici
  loadMove?: (move: OpeningMove) => void, // Autoriser l'optionnel ici aussi
  handleCloseModal?: () => void, // Optionnel maintenant
  idTree?: string
) {
  // Si loadMove est undefined, on utilise une fonction vide par défaut
  const handleLoadMove = loadMove || (() => {});

  return (
    <CustomNode
      nodeDatum={nodeDatum}
      handleCloseModal={handleCloseModal}
      handleMouseEnter={(event, history, comment) => {
        if (chessboardPreviewRef?.current) {
          chessboardPreviewRef.current.handleMouseEnter(
            event,
            history,
            comment
          );
        }
      }}
      handleMouseLeave={() => {
        if (chessboardPreviewRef?.current) {
          chessboardPreviewRef.current.handleMouseLeave();
        }
      }}
      idTree={idTree}
      currentMove={currentMove}
      loadMove={handleLoadMove} // On passe la fonction loadMove (ou une fonction vide)
    />
  );
}
