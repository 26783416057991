// useSubscriptionStore.tsx

import { create } from "zustand";
import { InfoSubscription } from "../types";

interface SubscriptionState {
  subscriptions: InfoSubscription[];
  basePricePerInterval: number; // Prix de référence fixe
  selectedSubscription: InfoSubscription | null;
  success: boolean;
  isLoadingPayment: boolean;
  setSuccess: (success: boolean) => void;
  selectSubscription: (subscription: InfoSubscription) => void;
  setLoadingPayment: (isLoading: boolean) => void;
  resetSubscription: () => void;
  fetchSubscriptions: () => Promise<void>;
  applyDiscount: (newPrice: number) => void; // Nouvelle méthode
}

export const useSubscriptionStore = create<SubscriptionState>((set, get) => ({
  subscriptions: [],
  basePricePerInterval: 0,
  selectedSubscription: null,
  success: false,
  isLoadingPayment: false,
  selectSubscription: (subscription: InfoSubscription) =>
    set({ selectedSubscription: subscription }),
  setSuccess: (success: boolean) => set({ success }),
  setLoadingPayment: (isLoading: boolean) => set({ isLoadingPayment: isLoading }),
  resetSubscription: () => set({ selectedSubscription: null, success: false }),

  // Nouvelle méthode pour appliquer une réduction
  applyDiscount: (newPrice: number) => {
    const { selectedSubscription, basePricePerInterval, subscriptions } = get();

    if (!selectedSubscription) return;

    const durationInIntervals = selectedSubscription.durationInIntervals;
    const originalPrice = basePricePerInterval * durationInIntervals;
    const discountPercent = ((originalPrice - newPrice) / originalPrice) * 100;

    // Mettre à jour le prix et le pourcentage de réduction
    const updatedSubscriptions = subscriptions.map((subscription) => {
      if (subscription.priceID === selectedSubscription.priceID) {
        return {
          ...subscription,
          price: newPrice.toFixed(2), // Mettre à jour le prix avec la réduction appliquée
          discount: Math.round(discountPercent * 100) / 100, // Arrondi à 2 décimales
        };
      }
      return subscription;
    });

    set({ subscriptions: updatedSubscriptions, selectedSubscription: { ...selectedSubscription, price: newPrice.toFixed(2), discount: Math.round(discountPercent * 100) / 100 } });
  },

  fetchSubscriptions: async () => {
    const API_URL = process.env.REACT_APP_API_URL || "";
    try {
      const response = await fetch(`${API_URL}/api/prices`);

      if (!response.ok) {
        throw new Error("Failed to fetch subscription information");
      }

      const data: InfoSubscription[] = await response.json();

      // Map the data to include duration in intervals
      const subscriptionsWithDuration = data.map((subscription) => {
        let durationInIntervals = 1; // Default to 1 interval
        if (subscription.interval.includes("mois")) {
          const match = subscription.interval.match(/(\d+)\s*mois/);
          if (match && match[1]) {
            durationInIntervals = parseInt(match[1], 10);
          }
        } else if (subscription.interval.includes("an")) {
          const match = subscription.interval.match(/(\d+)\s*an/);
          if (match && match[1]) {
            durationInIntervals = parseInt(match[1], 10) * 12;
          }
        }

        return {
          ...subscription,
          durationInIntervals,
        };
      });

      // Find the base plan with the shortest duration
      const basePlan = subscriptionsWithDuration.reduce((prev, curr) =>
        curr.durationInIntervals < prev.durationInIntervals ? curr : prev
      );

      const basePricePerInterval =
        parseFloat(basePlan.price) / basePlan.durationInIntervals;

      // Calculate the original price and discount for each plan
      const subscriptionsWithCalculatedPrices = subscriptionsWithDuration.map(
        (subscription) => {
          const durationInIntervals = subscription.durationInIntervals;
          const price = parseFloat(subscription.price);
          const pricePerInterval = price / durationInIntervals;

          const originalPrice = basePricePerInterval * durationInIntervals;

          let discount = 0;
          if (price < originalPrice) {
            discount = ((originalPrice - price) / originalPrice) * 100;
            discount = Math.round(discount * 100) / 100; // Round to 2 decimal places
          }

          return {
            ...subscription,
            originalPrice,
            pricePerInterval,
            discount,
          };
        }
      );

      // Set the subscriptions and base price in the store
      set({
        subscriptions: subscriptionsWithCalculatedPrices,
        basePricePerInterval,
      });
    } catch (error) {
      console.error("Error fetching subscription information:", error);
      throw error;
    }
  },
}));
