import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import hourglass from "../../../assets/hourglass.png";
import fireAnimation from "../../../assets/lottie/fire.json";
import target from "../../../assets/target.png";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import "./SessionStats.scss";

export default function SessionStats() {
  const { mode, errorCount, streakCount, nbrMove } = useLessonOpeningStore();

  const [sessionTime, setSessionTime] = useState(0);

  useEffect(() => {
    let timerInterval: any;

    const startTime = Date.now();

    timerInterval = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
      setSessionTime(elapsedTime);
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [mode]);

  return (
    <div className="session-stats">
      <div className="error-count">
        <FaExclamationTriangle className="icon" />
        {errorCount}
      </div>
      <div className="target-precision">
        <img src={target} className="target-icon" alt="target" />
        {nbrMove === 0
          ? "100%"
          : `${Math.min(
              Math.round(((+nbrMove - +errorCount) / +nbrMove) * 100),
              100
            )}%`}
      </div>

      <div className="correct-streak">
        <Lottie animationData={fireAnimation} className="lottie-fire" />
        {streakCount}
      </div>
      <div className="session-time">
        <img src={hourglass} className="hourglass-icon" alt="hourglass" />
        {sessionTime}
      </div>
    </div>
  );
}
