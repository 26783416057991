// useSettingChessboardStore.ts
import { create } from "zustand";

type Mode = "horizontal" | "vertical" | "none";

interface SettingChessboard {
  size: number;
  mode: Mode;
  setSize: (newSize: number) => void;
  setMode: (mode: Mode) => void;
}

export const useSettingChessboardStore = create<SettingChessboard>((set) => ({
  size: 400, // Taille par défaut du plateau
  mode: "horizontal",
  setSize: (newSize: number) => set({ size: newSize }),
  setMode: (mode: Mode) => set({ mode }),
}));
