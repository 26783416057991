import { OpeningMove, TreeOpeningData } from "../types";

export class OpeningManager {
  private openingTree: OpeningMove;
  private pageMap: Map<number, OpeningMove>;

  constructor(openingData: TreeOpeningData) {
    this.openingTree = openingData?.tree; // Utilise les données fournies ou les données mockées par défaut
    this.pageMap = new Map<number, OpeningMove>();
    this.buildPageMap(this.openingTree);
  }

  public getTreeOpeningData(): TreeOpeningData {
    // Créer un objet OpeningData en récupérant les valeurs de l'instance courante
    const openingData: TreeOpeningData = {
      tree: this.openingTree, // Récupérer l'arbre d'ouverture
    };

    // Retourner l'objet OpeningData
    return openingData;
  }

  /**
   * Construit une map associant chaque numéro de page unique à son nœud correspondant.
   * @param node Le nœud actuel de l'arbre.
   */
  private buildPageMap(node: OpeningMove) {
    if (node.path) {
      for (const page of node.path) {
        this.pageMap.set(page, node);
      }
    }
    for (const child of Object.values(node.children)) {
      this.buildPageMap(child);
    }
  }

  /**
   * Ajoute un nouveau coup dans l'arbre d'ouverture ou renvoie le nœud existant correspondant si le coup existe déjà.
   *
   * La fonction parcourt l'historique des coups pour trouver le bon nœud dans l'arbre d'ouverture.
   * Si un coup n'existe pas, il est ajouté. Si le coup existe déjà pour le dernier mouvement de l'historique,
   * la fonction ne fait rien mais renvoie tout de même le nœud correspondant au coup existant.
   *
   * @param {OpeningMove} moveData - Les données du mouvement à ajouter, incluant le SAN et l'historique.
   * @returns {OpeningMove} - Le nœud du coup ajouté ou trouvé.
   */
  public addMove(moveData: OpeningMove): OpeningMove {
    let currentNode = this.openingTree;

    // Parcourir l'historique pour trouver le nœud parent
    for (let i = 0; i < moveData.history.length; i++) {
      const san = moveData.history[i];

      // Si le nœud correspondant au coup n'existe pas, on le crée
      if (!currentNode.children[san]) {
        currentNode.children[san] = {
          san,
          evaluation: "good",
          blockEntrainement: false,
          arrows: [],
          circles: [],
          // Créer une nouvelle copie de l'historique à chaque fois
          history: [...(moveData.history || [])],
          path: [], // Mettez à jour selon vos besoins
          children: {},
          fen: moveData.fen,
        };
      } else if (i === moveData.history.length - 1) {
        // Si le nœud existe déjà pour le dernier coup de l'historique, ne rien faire, mais renvoyer le nœud existant
        return currentNode.children[san];
      }

      // Avancer dans l'arbre jusqu'au nœud enfant
      currentNode = currentNode.children[san];
    }

    // Reconstruire la pageMap après modification
    this.pageMap.clear();
    this.buildPageMap(this.openingTree);

    // Retourner le nœud actuel (le mouvement ajouté ou mis à jour)
    return currentNode;
  }

  /**
   * Met à jour un coup existant dans l'arbre d'ouverture.
   * @param moveData Les données à mettre à jour. `history` est obligatoire.
   * @returns {boolean} True si la mise à jour a réussi, sinon False.
   */
  public updateMove(
    moveData: Partial<OpeningMove> & { history: string[] }
  ): boolean {
    let currentNode = this.openingTree;

    // Parcourir l'historique pour trouver le coup à mettre à jour
    for (const san of moveData.history) {
      if (currentNode.children[san]) {
        currentNode = currentNode.children[san];
      } else {
        console.error("Coup non trouvé dans l'arbre d'ouverture.");
        return false;
      }
    }

    // Mettre à jour les données du coup
    Object.assign(currentNode, moveData);

    // Reconstruire la pageMap après modification
    this.pageMap.clear();
    this.buildPageMap(this.openingTree);

    return true;
  }

  /**
   * Supprime un coup de l'arbre d'ouverture.
   * @param move Le coup à supprimer (avec l'historique des coups menant à celui-ci).
   * @returns {OpeningMove | null} Le coup parent si la suppression a réussi, sinon null
   */
  public deleteMove(move: OpeningMove): OpeningMove | null {
    let currentNode = this.openingTree;
    let parentNode: OpeningMove | null = null;
    let sanToDelete: string | null = null;

    // Parcourir l'historique pour trouver le coup à supprimer
    for (const san of move.history) {
      if (currentNode.children[san]) {
        parentNode = currentNode;
        sanToDelete = san;
        currentNode = currentNode.children[san];
      } else {
        console.error("Coup non trouvé dans l'arbre d'ouverture.");
        return null;
      }
    }

    // Vérifier que le nœud à supprimer est bien une feuille (pas d'enfants)
    if (currentNode && Object.keys(currentNode.children).length === 0) {
      if (parentNode && sanToDelete) {
        console.log(`Suppression du coup : ${sanToDelete}`);
        delete parentNode.children[sanToDelete];

        this.pageMap.clear();
        this.buildPageMap(this.openingTree);

        // Renvoyer le parent (coup d'avant) après suppression
        return parentNode;
      } else {
        console.error("Impossible de supprimer le coup racine.");
        return null;
      }
    } else {
      console.error(
        "Le coup ne peut pas être supprimé car il a des coups enfants."
      );
      return null;
    }
  }

  /**
   * Récupère le premier coup de l'ouverture.
   * @returns {string} Le premier coup.
   */
  public getFirstMove(): string {
    return this.openingTree.san;
  }

  public getNextMoves(history: string[] | undefined): OpeningMove[] {
    let currentMove: OpeningMove | null = this.openingTree;

    if (!history || history.length === 0) {
      return Object.values(currentMove.children); // Retourner tous les coups disponibles au début
    }

    // Parcourir l'historique pour trouver le dernier coup joué dans l'arbre
    for (const move of history) {
      if (currentMove && currentMove.children[move]) {
        currentMove = currentMove.children[move];
      } else {
        return []; // Retourner un tableau vide si l'historique ne correspond à aucun chemin
      }
    }

    // Si aucun coup n'est disponible, retourner une indication ou un tableau vide
    if (
      !currentMove ||
      !currentMove.children ||
      Object.keys(currentMove.children).length === 0
    ) {
      console.log("fin de la ligne");
      return []; // Fin de la ligne d'ouverture, aucun coup suivant
    }

    // Retourner uniquement les enfants directs (profondeur 1)
    return Object.values(currentMove.children);
  }

  /**
   * Récupère les détails d'un coup en fonction de l'historique.
   * @param history L'historique des coups joués.
   * @returns {OpeningMove | null} Les détails du coup ou null s'il n'est pas trouvé.
   */
  public getMoveDetails(history: string[]): OpeningMove | null {
    if (history.length === 0) return null;

    let currentNode: OpeningMove | undefined = this.openingTree;

    for (let i = 0; i < history.length; i++) {
      currentNode = currentNode.children[history[i]];

      if (!currentNode) {
        return null;
      }
    }

    return currentNode || null;
  }

  /**
   * Récupère un coup en fonction du numéro de page.
   * @param page Le numéro de page.
   * @returns {OpeningMove | null} Le coup correspondant ou null s'il n'est pas trouvé.
   */
  public getMoveByPage(page: number): OpeningMove | null {
    return this.pageMap.get(page) || null;
  }

  /**
   * Récupère le numéro de la page suivante.
   * @param currentPage Le numéro de la page actuelle.
   * @returns {number | null} Le numéro de la page suivante ou null s'il n'y en a pas.
   */
  public getNextPage(currentPage: number): number | null {
    const pages = Array.from(this.pageMap.keys()).sort((a, b) => a - b);
    const currentIndex = pages.indexOf(currentPage);
    if (currentIndex >= 0 && currentIndex < pages.length - 1) {
      return pages[currentIndex + 1];
    }
    return null;
  }

  /**
   * Récupère le numéro de la page précédente.
   * @param currentPage Le numéro de la page actuelle.
   * @returns {number | null} Le numéro de la page précédente ou null s'il n'y en a pas.
   */
  public getPreviousPage(currentPage: number): number | null {
    const pages = Array.from(this.pageMap.keys()).sort((a, b) => a - b);
    const currentIndex = pages.indexOf(currentPage);
    if (currentIndex > 0) {
      return pages[currentIndex - 1];
    }
    return null;
  }

  /**
   * Récupère le coup suivant en fonction du numéro de page actuel.
   * @param currentPage Le numéro de la page actuelle.
   * @returns {OpeningMove | null} Le coup suivant ou null s'il n'y en a pas.
   */
  public getNextMoveByPage(currentPage: number): OpeningMove | null {
    const nextPage = this.getNextPage(currentPage);

    if (nextPage !== null) {
      return this.getMoveByPage(nextPage);
    }
    return null;
  }

  /**
   * Récupère le coup précédent en fonction du numéro de page actuel.
   * @param currentPage Le numéro de la page actuelle.
   * @returns {OpeningMove | null} Le coup précédent ou null s'il n'y en a pas.
   */
  public getPreviousMoveByPage(currentPage: number): OpeningMove | null {
    const prevPage = this.getPreviousPage(currentPage);
    if (prevPage !== null) {
      return this.getMoveByPage(prevPage);
    }
    return null;
  }

  /**
   * Récupère toutes les informations de mouvement précédent et le mouvement actuel.
   * @param history Liste des coups déjà joués
   * @returns {OpeningMove[]} Les détails de tous les coups, y compris le mouvement actuel
   */
  public getAllMovesDetails(history: string[]): OpeningMove[] {
    if (history.length === 0) return [];

    let currentNode: OpeningMove | undefined = this.openingTree;
    const allMoves: OpeningMove[] = [];

    // Vérifiez si le premier coup correspond au coup principal
    if (history[0] !== this.openingTree.history[0]) return [];

    // Parcours de l'historique
    for (let i = 0; i < history.length; i++) {
      if (i > 0) {
        currentNode = currentNode?.children[history[i]];
        if (!currentNode) {
          console.error(`Node not found for move: ${history[i]}`);
          break;
        }
      }
      allMoves.push({
        san: history[i],
        path: currentNode?.path || "",
        history: currentNode?.history || [], // Fournir un tableau vide si history est undefined

        evaluation: currentNode?.evaluation || "",
        arrows: currentNode?.arrows || [],
        circles: currentNode?.circles || [],
        children: currentNode?.children || {},
        blockEntrainement: currentNode?.blockEntrainement || false,
        fen: currentNode?.fen || "",
      });
    }

    return allMoves;
  }

  /**
   * Trouve le chemin le plus court entre deux coups basé sur leur historique.
   * @param startHistory L'historique complet des coups du coup de départ.
   * @param endHistory L'historique complet des coups du coup d'arrivée.
   * @returns {string[]} Un tableau représentant l'historique des coups du chemin le plus court, ou un tableau vide si aucun chemin n'est trouvé.
   */
  public findShortestPathBetweenMoves(
    startHistory: string[],
    endHistory: string[]
  ): OpeningMove[] {
    if (!this.openingTree || endHistory.length === 0) {
      return []; // Retourner un tableau vide si les historiques sont incomplets ou l'arbre est vide.
    }

    const startNode = this.getNodeByHistory(startHistory);
    const endNode = this.getNodeByHistory(endHistory);

    if (!startNode || !endNode) {
      console.error("One or both moves not found in the opening tree.");
      return [];
    }

    // Utiliser BFS pour trouver le chemin le plus court entre les deux noeuds.
    const queue = [{ node: startNode, path: [startNode] }];
    const visited = new Set<OpeningMove>([startNode]);

    while (queue.length > 0) {
      const { node, path } = queue.shift()!;
      if (node === endNode) {
        return path.map((move) => move); // Retourner l'historique des SAN du chemin trouvé.
      }

      Object.values(node.children).forEach((child) => {
        if (!visited.has(child)) {
          visited.add(child);
          queue.push({ node: child, path: [...path, child] });
        }
      });
    }

    return []; // Retourner un tableau vide si aucun chemin n'est trouvé.
  }

  /**
   * Récupère un noeud dans l'arbre d'ouverture basé sur l'historique complet des coups.
   * @param history L'historique des coups menant au noeud désiré.
   * @returns {OpeningMove | null} Le noeud correspondant ou null si non trouvé.
   */
  private getNodeByHistory(history: string[]): OpeningMove | null {
    let currentNode = this.openingTree;
    for (const move of history) {
      currentNode = currentNode.children[move];
      if (!currentNode) {
        return null;
      }
    }
    return currentNode;
  }

  /**
   * Vérifie que tous les mouvements de l'arbre d'ouvertures ont au moins un path.
   * @returns {boolean} True si tous les mouvements ont un path, sinon False.
   */
  public checkAllMovesHavePath(): boolean {
    const checkNode = (node: OpeningMove): boolean => {
      // Vérifie si le nœud a un path
      if (!node.path || node.path.length === 0) {
        return false; // Retourne false si le path est vide
      }

      // Vérifie récursivement tous les enfants
      for (const child of Object.values(node.children)) {
        if (!checkNode(child)) {
          return false; // Si un enfant ne passe le test, retourne false
        }
      }

      return true; // Retourne true si le nœud et tous ses enfants ont un path
    };

    return checkNode(this.openingTree); // Commence la vérification à partir de la racine
  }

  /**
   * Récupère l'arbre d'ouverture complet.
   * @returns {OpeningMove} L'arbre d'ouverture.
   */
  public getOpeningTree(): OpeningMove {
    return this.openingTree;
  }

  /**
   * Récupère un mouvement aléatoire dans l'arbre d'ouverture, avec une profondeur minimale et maximale.
   * Le mouvement doit correspondre à la couleur spécifiée et ne doit pas être une feuille (doit avoir des enfants).
   * @param minDepth La profondeur minimale.
   * @param maxDepth La profondeur maximale.
   * @param playerColor Le joueur à jouer ("white" ou "black").
   * @returns {OpeningMove | null} Un mouvement aléatoire ou null s'il n'est pas trouvé.
   */
  public getRandomMove(
    minDepth: number = 1,
    maxDepth: number = 5,
    playerColor: "white" | "black"
  ): OpeningMove | null {
    const moves: OpeningMove[] = [];

    const traverse = (node: OpeningMove, depth: number) => {
      // Déterminer si c'est au tour du joueur spécifié
      const isPlayerTurn =
        (depth % 2 === 1 && playerColor === "white") ||
        (depth % 2 === 0 && playerColor === "black");

      if (
        depth >= minDepth &&
        depth <= maxDepth &&
        isPlayerTurn &&
        Object.keys(node.children).length > 0
      ) {
        moves.push(node);
      }

      if (depth >= maxDepth) {
        return; // On ne va pas plus profond que la profondeur maximale
      }

      // Parcourir les enfants du nœud actuel
      for (const child of Object.values(node.children)) {
        traverse(child, depth + 1);
      }
    };

    // Commencer le parcours à partir de la racine avec une profondeur de 0
    traverse(this.openingTree, 0);

    if (moves.length === 0) {
      return null; // Aucun mouvement trouvé correspondant aux critères
    }

    // Sélectionner aléatoirement un mouvement parmi ceux collectés
    const randomIndex = Math.floor(Math.random() * moves.length);
    return moves[randomIndex];
  }
}
