import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { config as springConfig } from "react-spring";
import Carousel from "react-spring-3d-carousel";
import { useSwipeable } from "react-swipeable";
import { v4 as uuidv4 } from "uuid";
import PricingCard from "../../commun/PricingCard/PricingCard";
import { useSubscriptionStore } from "../../../store/useSubscriptionStore";
import "./ChoiceSubscription.scss";

Modal.setAppElement("#root");

interface ChoiceSubscriptionProps {
  // Removed 'data' prop since we'll fetch data from the store
}

const ChoiceSubscription: React.FC<ChoiceSubscriptionProps> = () => {
  const [goToSlide, setGoToSlide] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);
  const { subscriptions, fetchSubscriptions } = useSubscriptionStore();

  const offsetRadius = 1;

  const handleCardClick = (index: number) => {
    setGoToSlide(index);
  };

  const handleSwipeLeft = () => {
    setGoToSlide((prevSlide) => (prevSlide + 1) % subscriptions.length);
  };

  const handleSwipeRight = () => {
    setGoToSlide((prevSlide) =>
      prevSlide === 0 ? subscriptions.length - 1 : prevSlide - 1
    );
  };

  const calculateTransformation = (isLeft: boolean) => {
    const screenWidth = window.innerWidth;

    let translateX: number;
    let scale: number;

    if (screenWidth > 1200) {
      translateX = isLeft ? -70 : -30;
      scale = 0.8;
    } else if (screenWidth > 768) {
      translateX = isLeft ? -55 : -45;
      scale = 0.8;
    } else if (screenWidth > 468) {
      translateX = isLeft ? -10 : -90;
      scale = 0.8;
    } else {
      translateX = isLeft ? 20 : -120;
      scale = 0.8;
    }

    return `translateY(-50%) translateX(${translateX}%) scale(${scale})`;
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    trackMouse: true,
  });

  const slides = subscriptions.map((card, index) => ({
    key: uuidv4(),
    content: (
      <div onClick={() => handleCardClick(index)}>
        <PricingCard data={card} />
      </div>
    ),
  }));

  return (
    <div className="choiceSubscription" {...handlers}>
      <Carousel
        slides={slides}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={false}
        animationConfig={springConfig.wobbly}
        offsetFn={(offsetFromCenter) => {
          if (offsetFromCenter === 0) {
            return {
              opacity: 1,
              transform: "translateY(-50%) translateX(-50%) scale(1)",
              left: "50%",
            };
          }

          const isLeft = offsetFromCenter < 0;

          return {
            opacity: 0.9,
            transform: calculateTransformation(isLeft),
          };
        }}
      />
    </div>
  );
};

export default ChoiceSubscription;
