import React from "react";
import icons from "../../../assets/svg/icons";
import { useAuthPremium } from "../../../contexts/AuthPremiumContext";
import "./NotLoggedInPremium.scss"; // Importation du fichier SCSS

const NotLoggedInPremium: React.FC = () => {
  const { checkAccess } = useAuthPremium();

  return (
    <div className="container">
      {icons({ width: 90, height: 90 }).premium}
      <h1 className="title">
        Veuillez vous connecter pour accéder à la Premium.
      </h1>
      <button className="login-button" onClick={() => checkAccess("login")}>
        Se connecter
      </button>
    </div>
  );
};

export default NotLoggedInPremium;
