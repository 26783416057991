import { useEffect, useState } from "react";
// Importer Helmet pour ajouter les balises SEO
import { Helmet } from "react-helmet";
import ChessboardCoursEntrainement from "../../components/ChessboardCoursEntrainement/ChessboardCoursEntrainement";
import Header from "../../components/commun/header/header";

import { useParams } from "react-router-dom";
import LoaderChess from "../../components/commun/LoaderChess/LoaderChess";

import axios from "axios";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import { OpeningDataHomePage } from "../../types";
import "./LearnTraining.scss";
const API_URL = process.env.REACT_APP_API_URL || "";

export default function LearnTraining() {
  const { openingPath } = useParams(); // Récupère le paramètre d'URL

  const [loading, setLoading] = useState(true);

  function extractTitleAndCreator(str: string) {
    const [title, creator] = str.split("-by-");
    return { title, creator };
  }

  const {
    setOpeningTree,
    setInfoOpeningData,
    infoOpeningData,
    setComment,
    resetStoreExceptOpening,
  } = useLessonOpeningStore();

  useEffect(() => {
    // Simuler une requête pour récupérer les données de l'ouverture avec un délai
    async function fetchOpenings(title: string, creator: string) {
      try {
        const response = await axios.get(
          `${API_URL}/api/get-openings-by-title-and-creator`,
          {
            params: {
              title,
              creator,
              includeData: true, // Passez true pour inclure "data" ou false pour l'exclure
            },
          }
        );

        const infoOpeningData: OpeningDataHomePage = {
          _id: response.data._id,
          title: response.data.title,
          creator: response.data.creator,
          description: response.data.description,
          level: response.data.level,

          pathForPremium: response.data.pathForPremium,
          history: response.data.history,
          color: response.data.color,
          nbrOfPath: response.data.nbrOfPath,
        };

        const commentOpeningTreeParse = JSON.parse(response.data.data);

        const comments = commentOpeningTreeParse.comments;

        const openingTree = commentOpeningTreeParse.openingTree;

        setOpeningTree(openingTree);
        setInfoOpeningData(infoOpeningData);
        setComment(comments);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 404) {
            console.error(
              "Aucune ouverture trouvée pour ce nom et ce créateur."
            );
          } else {
            console.error(
              "Erreur lors de la récupération des ouvertures :",
              error.message
            );
          }
        } else {
          console.error("Erreur inconnue :", error);
        }
      }

      setLoading(false);
    }

    const { title, creator } = extractTitleAndCreator(openingPath!);
    fetchOpenings(title, creator);

    return () => {
      resetStoreExceptOpening(); // Réinitialise le store lorsque le composant est démonté
    };
  }, [openingPath]);

  if (loading) {
    return <LoaderChess />;
  }

  // Titre dynamique basé sur l'ouverture
  const pageTitle = `Learn ${infoOpeningData.title} - Chess Openings`;

  return (
    <div className="learnTraining">
      {/* SEO avec Helmet */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={infoOpeningData.description} />
        <meta
          name="keywords"
          content={`chess, opening, learn, ${infoOpeningData.title}, chess training`}
        />
        <meta property="og:title" content={pageTitle} />
        <meta
          property="og:description"
          content={`Detailed guide and training for the ${infoOpeningData.title} opening in chess.`}
        />
      </Helmet>
      {/* <SidebarCustom /> */}

      <Header />
      <div className="learnTraining_content">
        <ChessboardCoursEntrainement />
      </div>
    </div>
  );
}
