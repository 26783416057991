import axios from "axios";
import { create } from "zustand";

const API_URL = process.env.REACT_APP_API_URL || "";

interface UserState {
  isLogged: boolean;
  role: "user" | "creator" | "admin" | null;
  email: string | null;
  name: string | null;
  userId: string | null;
  pseudo: string | null;
  subscriptionExpiry: Date | null;
  setSubscriptionExpiry: (expiry: Date) => void;
  setIsLogged: (isLogged: boolean) => void;
  checkLoginStatus: () => Promise<boolean>;
  updateSubscriptionExpiryInBDD: () => Promise<boolean>;
  isPremium: () => Promise<boolean>;
}

export const useUserStore = create<UserState>((set, get) => ({
  // Initial state

  isLogged: false,
  role: null,
  email: null,
  name: null,
  pseudo: null,
  userId: null,
  subscriptionExpiry: null,

  // Setters
  setIsLogged: (isLogged: boolean): void => {
    set({ isLogged });
  },

  setSubscriptionExpiry: (expiry: Date): void => {
    set({ subscriptionExpiry: expiry });
  },

  updateSubscriptionExpiryInBDD: async (): Promise<boolean> => {
    try {
      // Request user info from the backend
      const response = await axios.get(
        `${API_URL}/api/update-subscription-expiry`,
        {
          withCredentials: true,
        }
      );

      const subscriptionExpiry = response.data;
      if (subscriptionExpiry === null) return false;
      return new Date(subscriptionExpiry) > new Date();
    } catch (error) {
      console.error("Error checking login status:", error);
      set({
        isLogged: false,
        role: null,
        email: null,
        name: null,
        userId: null,
        subscriptionExpiry: null,
      });
      return false;
    }
  },
  // Helper function to determine if the user is premium
  isPremium: async (): Promise<boolean> => {
    const state = get();
    if (state.subscriptionExpiry) {
      if (new Date(state.subscriptionExpiry) <= new Date())
        return await state.updateSubscriptionExpiryInBDD();
      else return new Date(state.subscriptionExpiry) > new Date();
    }
    return false;
  },

  // Function to check if the user is logged in
  checkLoginStatus: async (): Promise<boolean> => {
    try {
      // Request user info from the backend
      const response = await axios.get(`${API_URL}/api/user-info`, {
        withCredentials: true,
      });

      const userInfo = response.data;

      set({
        isLogged: true,
        role: userInfo.role,
        email: userInfo.email,
        name: userInfo.name,
        pseudo: userInfo.pseudo,
        userId: userInfo.userId,
        subscriptionExpiry: userInfo.subscriptionExpiry
          ? new Date(userInfo.subscriptionExpiry)
          : null,
      });
      return true;
    } catch (error) {
      console.error("Error checking login status:", error);
      set({
        isLogged: false,
        role: null,
        email: null,
        name: null,
        userId: null,
        subscriptionExpiry: null,
      });
      return false;
    }
  },
}));
