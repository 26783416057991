// components/ModalEditInfoOpening.tsx
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import useCreateOpeningStore from "../../store/useCreateOpeningStore";
import { useUserStore } from "../../store/useUserStore";
import { OpeningDataHomePage } from "../../types";

import axios from "axios";
import { toast } from "react-toastify";
import ColorSelectorBoard from "../commun/ColorSelectorBoard/ColorSelectorBoard";
import ImageResizer from "../commun/ImageResizer/ImageResizer";
import LessonCard from "../commun/LessonCard/LessonCard";
import LevelSelector from "../commun/LevelSelector/LevelSelector";
import "./ModalEditInfoOpening.scss";

const API_URL = process.env.REACT_APP_API_URL || "";
Modal.setAppElement("#root");

interface ModalEditInfoOpeningProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

export default function ModalEditInfoOpening({
  isOpen,
  onRequestClose,
}: ModalEditInfoOpeningProps) {
  const {
    infoOpeningData,
    updateInfoOpeningData,
    checkAllMovesHavePath,
    getOpeningData,
    comments,
  } = useCreateOpeningStore();

  const [formData, setFormData] = useState({
    title: infoOpeningData.title || "",
    description: infoOpeningData.description || "",
    image: null as File | null,
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isSaving, setIsSaving] = useState(false);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const { pseudo } = useUserStore();

  useEffect(() => {
    if (isOpen) {
      setFormData({
        title: infoOpeningData.title,
        description: infoOpeningData.description,

        image: null,
      });
    }
  }, [isOpen]); // Retirer infoOpeningData des dépendances

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Mettre à jour formData immédiatement
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (pseudo) {
      const newInfoOpeningData: OpeningDataHomePage = {
        ...infoOpeningData,
        creator: pseudo,
      };
      updateInfoOpeningData(newInfoOpeningData);
    }
  }, [pseudo]);

  // Utiliser useEffect avec debounce pour mettre à jour infoOpeningData
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!infoOpeningData) return;

      const newInfoOpeningData: OpeningDataHomePage = {
        ...infoOpeningData,
        ...formData,
      };

      updateInfoOpeningData(newInfoOpeningData);
    }, 300); // Délai de 300ms

    // Nettoyer le timeout si formData change avant la fin du délai
    return () => {
      clearTimeout(handler);
    };
  }, [formData]); // Se déclenche à chaque changement de formData

  // if (!validateForm()) return;
  const handleSave = async () => {
    console.log(infoOpeningData.creator);
    try {
      if (!checkAllMovesHavePath()) {
        throw new Error("Tous les mouvements doivent avoir un chemin valide.");
      }

      setIsSaving(true);

      const formData = new FormData();

      // Ajouter les champs textuels
      formData.append("title", infoOpeningData.title);
      formData.append("description", infoOpeningData.description);
      formData.append("creator", infoOpeningData.creator);
      formData.append("level", String(infoOpeningData.level));
      formData.append("color", infoOpeningData.color);
      formData.append("nbrOfPath", String(infoOpeningData.nbrOfPath));
      formData.append(
        "data",
        JSON.stringify({
          comments: comments,
          openingTree: getOpeningData(),
        })
      );
      formData.append("pathForPremium", String(infoOpeningData.pathForPremium));
      formData.append("history", JSON.stringify(infoOpeningData.history));

      if (infoOpeningData._id) {
        formData.append("_id", JSON.stringify(infoOpeningData._id));
      }

      // Ajouter l'image croppée si disponible
      if (croppedImage) {
        const byteString = atob(croppedImage.split(",")[1]);
        const mimeString = croppedImage
          .split(",")[0]
          .split(":")[1]
          .split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        formData.append("image", blob, "croppedImage.png");
      }

      // Envoyer les données
      const response = await axios.post(
        `${API_URL}/api/save-openings`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          withCredentials: true,
        }
      );

      toast.success(response.data.message, {
        autoClose: 5000,
        position: "top-right",
        theme: "colored",
      });
      onRequestClose(); // Fermer le modal après sauvegarde
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message || error.message || "Erreur inconnue.";
      console.error("Erreur lors de la sauvegarde des données :", errorMessage);
      toast.error(`Erreur : ${errorMessage}`, {
        autoClose: 5000,
        position: "top-right",
        theme: "colored",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangeColorBoard = () => {
    if (!infoOpeningData || !infoOpeningData.color) return;
    console.log(infoOpeningData.color);
    const newInfoOpeningData: OpeningDataHomePage = {
      ...infoOpeningData,
      color: infoOpeningData.color === "w" ? "b" : "w",
    };
    updateInfoOpeningData(newInfoOpeningData);
  };

  const handleLevelChange = (level: 1 | 2 | 3 | null) => {
    if (!infoOpeningData) return;
    const newInfoOpeningData: OpeningDataHomePage = {
      ...infoOpeningData,
      level: level!,
    };
    updateInfoOpeningData(newInfoOpeningData);
  };

  const handleImageCrop = (newImage: string) => {
    setCroppedImage(newImage);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Modifier les informations"
      className="modal-edit animated-modal"
      overlayClassName="modal-overlay animated-overlay"
    >
      <h2 className="modal-title">Information de l'ouverture</h2>
      <div className="modal-content">
        {/* Photo */}
        <div className="imgLesson-container">
          {croppedImage ? (
            <img src={croppedImage} alt="Profile" className="profile-img" />
          ) : (
            <div className="img-placeholder">
              <img
                src={`${API_URL}/api/get-opening-picture/${infoOpeningData._id}`}
                alt="pictureLesson"
              />
            </div>
          )}
          <ImageResizer onImageCrop={handleImageCrop} />
        </div>

        {/* Titre et description */}
        <div className="form-fields">
          <div className="form-group">
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              placeholder="Nom de l'ouverture"
            />
            {errors.title && <small className="error">{errors.title}</small>}
          </div>
          <div className="form-group">
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Description courte"
              rows={4}
              className="custom-scrollbar"
            />
            {errors.description && (
              <small className="error">{errors.description}</small>
            )}
          </div>
        </div>

        {/* Couleur et niveau */}
        <div className="additional-options">
          <ColorSelectorBoard
            selectedColor={infoOpeningData.color}
            onColorSelect={handleChangeColorBoard}
          />
          <LevelSelector
            selectedLevel={infoOpeningData.level}
            onLevelChange={handleLevelChange}
          />
        </div>

        {/* Prévisualisation */}
        <div className="previsualisationLessonCard">
          <LessonCard dataOpening={{ ...infoOpeningData, ...formData }} />
        </div>
      </div>

      {/* Boutons */}
      <div className="form-actions">
        <button
          type="button"
          className="save-btn"
          onClick={handleSave}
          disabled={isSaving}
        >
          {isSaving ? "En cours..." : "Sauvegarder"}
        </button>
        <button
          type="button"
          className="cancel-btn"
          onClick={onRequestClose}
          disabled={isSaving}
        >
          Annuler
        </button>
      </div>
    </Modal>
  );
}
