import { useEffect } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdFitness } from "react-icons/io";
import { PiGraduationCap, PiTreeStructureLight } from "react-icons/pi";
// Utiliser le contexte AuthPremium
import { useAuthPremium } from "../../../../contexts/AuthPremiumContext";
import { useModalTree } from "../../../../contexts/ModalTreeContext";
import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";
import { OpeningMove } from "../../../../types";
import Tooltip from "../../Tooltip/Tooltip";
import "./LearnPanel.scss";

const LearnPanel = () => {
  const { pathWithAccessCheck } = useAuthPremium();
  const {
    currentMove,
    handleUndoMove,
    handleNextMove,
    loadMoveWrapper,
    getOpeningTreeConverted,
    infoOpeningData,
    setMode,
  } = useLessonOpeningStore();

  const {
    openModalTree,
    setTreeNode,
    setCurrentMove,
    setLoadMove,
    setTreeId,
    setColor,
    setLegende,
  } = useModalTree();

  // Fonction pour l'annulation du mouvement (sans restriction d'accès)
  const processUndoMove = () => {
    handleUndoMove(); // L'utilisateur peut toujours annuler un mouvement, même sans être connecté ou premium
  };

  // Fonction pour avancer dans les mouvements (avec restrictions d'accès)
  const processNextMove = async () => {
    await pathWithAccessCheck(handleNextMove, currentMove.path[0]);
  };

  // Gérer l'ouverture de la modal d'arbre
  const handleModalTree = () => {
    const treeNode = getOpeningTreeConverted();
    const move = currentMove;

    setTreeNode(treeNode);
    setCurrentMove(move);

    // Passe une fonction anonyme qui appelle plusieurs fonctions, dont `loadMoveWrapper`
    setLoadMove(() => async (moveDetail: OpeningMove) => {
      // Utilise une fonction anonyme pour retarder l'exécution de `loadMoveWrapper`
      await pathWithAccessCheck(
        () => loadMoveWrapper(moveDetail),
        moveDetail.path[0]
      );
    });

    setTreeId("modaltreecours");
    setLegende(null);
    setColor(infoOpeningData.color);
    openModalTree();
  };

  // Ajouter la gestion des flèches du clavier pour undo et next
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft") {
        processUndoMove(); // Flèche gauche pour annuler le mouvement
      } else if (event.key === "ArrowRight") {
        processNextMove(); // Flèche droite pour avancer au mouvement suivant
      }
    };

    // Attacher l'événement keydown
    window.addEventListener("keydown", handleKeyDown);

    // Nettoyage de l'événement au démontage du composant
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentMove]); // Dépendance à currentMove pour garantir la mise à jour correcte des mouvements

  return (
    <div className="learn-panel">
      <button
        type="button"
        aria-haspopup="dialog"
        className="learn-panel_btn"
        onClick={processUndoMove} // L'annulation est toujours possible
      >
        <div className="learn-panel_btn_arrowLeft">
          <FaArrowLeft />
        </div>
      </button>

      <button
        type="button"
        aria-haspopup="dialog"
        className="learn-panel_btn"
        onClick={processNextMove} // L'avancement dépend des vérifications d'accès
      >
        <div className="learn-panel_btn_arrowRight">
          <FaArrowRight />
        </div>
      </button>

      <div className="divider"></div>
      <Tooltip text="Arbre d'ouverture">
        <button
          type="button"
          className="learn-panel_btn"
          onClick={handleModalTree}
        >
          <div className="training-panel_btn_tree">
            <PiTreeStructureLight />
          </div>
        </button>
      </Tooltip>

      <Tooltip text="Mode entrainement">
        <button
          type="button"
          aria-haspopup="dialog"
          aria-expanded="false"
          aria-controls="radix-:r4c:"
          className="learn-panel_btn"
          onClick={() => {
            setMode("entrainement");
          }}
        >
          <div className="learn-panel_btn_training">
            <IoMdFitness />
          </div>
        </button>
      </Tooltip>

      <Tooltip text="Mode evaluation">
        <button
          type="button"
          aria-haspopup="dialog"
          aria-expanded="false"
          aria-controls="radix-:r4c:"
          className="learn-panel_btn"
          onClick={() => {
            setMode("graduation");
          }}
        >
          <div className="learn-panel_btn_graduation">
            <PiGraduationCap />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

export default LearnPanel;
