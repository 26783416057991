import { useEffect, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import {
  fetchOpeningsByCreator,
  fetchOpeningsData,
} from "../../../services/api";
import { useSearchOpening } from "../../../store/useSearchOpening";
import { useUserStore } from "../../../store/useUserStore";
import { OpeningDataHomePage } from "../../../types";
import LessonCard from "../LessonCard/LessonCard";
import "./OpeningGrid.scss";

const ITEMS_PER_LOAD = 12;

interface OpeningGridProps {
  isEditing?: boolean;
}

const OpeningGrid: React.FC<OpeningGridProps> = ({ isEditing = false }) => {
  const [currentItemsCount, setCurrentItemsCount] = useState(ITEMS_PER_LOAD);
  const [isLoading, setIsLoading] = useState(true);
  const { openingsData, setOpeningsData, filteredOpenings } =
    useSearchOpening();
  const contentRef = useRef<HTMLDivElement>(null);
  const { pseudo } = useUserStore();

  const loadMoreItems = () => {
    if (currentItemsCount < openingsData.length) {
      setCurrentItemsCount((prevCount) => prevCount + ITEMS_PER_LOAD);
    }
  };

  const fetchOpenings = async () => {
    try {
      setIsLoading(true);
      let data: OpeningDataHomePage[];

      if (isEditing) {
        data = await fetchOpeningsByCreator(pseudo!);
      } else {
        data = await fetchOpeningsData();
      }

      setOpeningsData(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des ouvertures :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOpenings();
    return () => {
      console.log("OpeningGrid démonté");
    };
  }, [isEditing]);

  useEffect(() => {
    setCurrentItemsCount(ITEMS_PER_LOAD);
  }, [isEditing]);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const contentRect = contentRef.current.getBoundingClientRect();
        if (window.innerHeight >= contentRect.bottom - 1) {
          loadMoreItems();
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentItemsCount, filteredOpenings.length]);

  const handleDeleteOpening = (id: string) => {
    const updatedOpenings = openingsData.filter(
      (opening) => opening._id !== id
    );
    setOpeningsData(updatedOpenings);
  };

  const currentItems = filteredOpenings.slice(0, currentItemsCount);

  return (
    <div className="opening-grid" ref={contentRef}>
      {isLoading ? (
        <div
          style={{
            paddingTop: "100px",
          }}
        >
          <FaSpinner
            className="promo-code-loader"
            style={{
              color: "#ffffff",
              animation: "spin 1s linear infinite",
              fontSize: "40px",
            }}
          />
        </div>
      ) : (
        currentItems.map((opening, index) => (
          <LessonCard
            key={opening.title || index}
            dataOpening={opening}
            isEditing={isEditing}
            onDelete={handleDeleteOpening} // Passe la fonction de suppression
          />
        ))
      )}
    </div>
  );
};

export default OpeningGrid;
