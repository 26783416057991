import axios from "axios";
import { useState } from "react";
import Header from "../../components/commun/header/header";
import TabContent from "../../components/TabNavigation/TabContent/TabContent";
import TabNavigation from "../../components/TabNavigation/TabNavigation";
import UserInfo from "../../components/UserInfo/UserInfo";
import "./Profil.scss";
const API_URL = process.env.REACT_APP_API_URL || ""; // URL de base de l'API

export default function Profil() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [activeTab, setActiveTab] = useState<"subscription" | "cours">(
    "subscription"
  );

  const handleUnsubscribe = async () => {
    setLoading(true);
    setMessage("");

    try {
      // Appel à l'API pour se désabonner
      const response = await axios.post(
        `${API_URL}/api/unsubscribe`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Ajouter le token d'authentification si nécessaire
          },
        }
      );

      // Gérer la réponse de succès
      if (response.status === 200) {
        setMessage(response.data.message);
      }
    } catch (error) {
      console.error("Erreur lors de la désinscription :", error);
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          setMessage(`Erreur: ${error.response.data.error}`);
        } else {
          setMessage("Erreur inconnue lors de la désinscription.");
        }
      } else {
        setMessage("Erreur inconnue lors de la désinscription.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="pageProfil">
      <Header />
      <div className="contentProfil">
        <UserInfo />
        <TabNavigation activeTab={activeTab} onTabChange={setActiveTab} />
        <TabContent activeTab={activeTab} />

        <button onClick={handleUnsubscribe} disabled={loading}>
          {loading ? "Annulation en cours..." : "Se désabonner"}
        </button>
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}
