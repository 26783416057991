import { useState } from "react";
import user from "../../../../assets/user.png";
import { useLessonOpeningStore } from "../../../../store/useLessonOpeningStore";
import "./InfoCreateur.scss";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
const API_URL = process.env.REACT_APP_API_URL || "";

export default function InfoCreateur() {
  const [isFavorited, setIsFavorited] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const { infoOpeningData } = useLessonOpeningStore();

  const toggleFavorite = () => setIsFavorited(!isFavorited);
  const toggleSubscription = () => setIsSubscribed(!isSubscribed);

  return (
    <div className="info-createur">
      <div className="card">
        <div className="creator-info">
          <div className="creator-image">
            <img
              src={`${API_URL}/user/picture/${infoOpeningData.creator}`}
              alt="Profil"
              className="creator-image_image"
              onError={(e) => (e.currentTarget.src = user)} // Remplace l'image si elle échoue
            />
            <div className="creator-name-wrapper">
              <p className="creator-name">{infoOpeningData.creator}</p>
            </div>
          </div>


          <div className="text-info">
            <h2 className="course-name">
              {infoOpeningData.title.toLocaleUpperCase()}
            </h2>
          </div>

          <div className="action-buttons">
            <button className="btn-favorite" onClick={toggleFavorite}>
              {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
            </button>
            <button className="btn-subscribe" onClick={toggleSubscription}>
              {isSubscribed ? <NotificationsIcon /> : <NotificationsNoneIcon />}
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}
