import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import { useSiteSettingsStore } from "../../../store/useSiteSettingsStore";
import { Evaluation } from "../../../types";
import "./MoveDetails.scss";

const defaultMessages = {
  mistake: "Coup sous-optimal dans cette position.",
  perfect: "Coup optimal, correspondant à la meilleure continuation.",
  error: "Coup incorrect ; des options plus efficaces sont disponibles.",
  good: "Coup correct et cohérent avec la position.",
};

const MoveDetails: React.FC = () => {
  const { getComment, currentMove } = useLessonOpeningStore();
  const { language } = useSiteSettingsStore();

  // Récupère un commentaire spécifique ou utilise un message générique par défaut
  const message =
    getComment(currentMove.fen, language) ||
    defaultMessages[currentMove.evaluation as keyof Evaluation];

  return (
    <div className="move-details-container custom-scrollbar">
      <ReactQuill
        value={message} // Passez le message comme valeur
        readOnly={true} // Mode lecture seule
        theme="bubble" // Utilisez un thème léger (bubble ou snow)
        modules={{ toolbar: false }} // Désactivez la barre d'outils
      />
    </div>
  );
};

export default MoveDetails;
