import React, { useEffect, useState } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import Modal from "react-modal";
import { fetchSubscriptionPrices } from "../../services/api";
import { useSubscriptionStore } from "../../store/useSubscriptionStore";
import { useUserStore } from "../../store/useUserStore";
import { InfoSubscription } from "../../types";
import LogicBuy from "./LogicBuy/LogicBuy";
import NotLoggedInPremium from "./NotLoggedInPremium/NotLoggedInPremium";
import "./PayementModal.scss";

Modal.setAppElement("#root");

interface PayementModalProps {
  isOpen: boolean;
  closeModal: () => void;
  overlayPointerEvent: boolean;
}

const PayementModal: React.FC<PayementModalProps> = ({
  isOpen,
  closeModal,
  overlayPointerEvent,
}) => {
  const { isPremium, isLogged } = useUserStore();
  const [subscriptions, setSubscriptions] = useState<InfoSubscription[]>([]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  useEffect(() => {
    if (isLogged && !isPremium()) {
      fetchSubscriptionPrices()
        .then((data) => setSubscriptions(data))
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération des abonnements :",
            error
          );
          // Optionnel : gérer l'erreur en affichant un message à l'utilisateur
        });
    }
  }, [isLogged, isPremium]);

  const { isLoadingPayment } = useSubscriptionStore();

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={!overlayPointerEvent}
      contentLabel="payement Modal"
      className="payementModal"
      overlayClassName={`payementModal-overlay`}
    >
      {isLoadingPayment ? null : (
        <div onClick={closeModal}>
          <RiCloseCircleLine className="payementModal_close-icon" />
        </div>
      )}

      {!isLogged && (
        <div className="ContentNotLoggedInPremium">
          <NotLoggedInPremium />
        </div>
      )}
      {/* {isLogged && isPremium && <PremiumMember />} */}
      {isLogged && !isPremium() && (
        <>
          <LogicBuy data={subscriptions} />
        </>
      )}
    </Modal>
  );
};

export default PayementModal;
