import { useEffect, useState } from "react";
import { useAnnotationsStore } from "../../../store/useAnnotationsStore";
import { GameChess } from "../../../utils/GameChess";
import ChessboardManager from "../ChessboardManager/ChessboardManager";

interface ChessboardPresentationProps {
  history: string[]; // Un tableau de coups en notation SAN
  color: "b" | "w";
}

const ChessboardPresentation: React.FC<ChessboardPresentationProps> = ({
  color,
  history,
}) => {
  const { removeAllAnnotations } = useAnnotationsStore();
  useEffect(() => {
    removeAllAnnotations(); //Permet de renitialiser les anotation dans le store si utilisation de chessboard presentation pas ideal a retravailler
  }, []);

  const [gameChess] = useState(new GameChess()); // Crée une instance de GameChess
  const [position, setPosition] = useState(gameChess.getBoardPosition()); // Initialise la position à la position initiale

  const [currentMoveIndex, setCurrentMoveIndex] = useState(0); // Ajoute un état pour suivre le coup en cours

  // Fonction pour jouer le coup suivant de l'historique
  const playNextMove = () => {
    if (currentMoveIndex < history.length) {
      const nextMove = history[currentMoveIndex]; // Récupère le prochain coup dans l'historique
      gameChess.move(nextMove); // Joue le coup en notation SAN
      setPosition(gameChess.getBoardPosition()); // Met à jour la position du plateau

      setCurrentMoveIndex(currentMoveIndex + 1); // Incrémente l'index pour le coup suivant
    }
  };

  // Utiliser useEffect pour jouer les coups de l'historique au montage, puis régulièrement
  useEffect(() => {
    const interval = setInterval(() => {
      // Vérifie le statut du jeu pour échec et mat, partie nulle, etc.
      if (currentMoveIndex < history.length) {
        playNextMove(); // Joue le prochain coup de l'historique si la partie est toujours en cours
      } else {
        clearInterval(interval); // Arrête de jouer des coups si la partie est terminée
      }
    }, 1000); // Joue un coup toutes les secondes

    return () => clearInterval(interval); // Nettoyage de l'intervalle
  }, [currentMoveIndex]);

  return (
    <ChessboardManager
      gameChess={gameChess} // Passer la position mise à jour au ChessboardManager
      customArrows={[]} // Pas de flèches personnalisées pour le moment
      arePiecesDraggable={false} // Désactiver le drag pour cet exemple
      onPieceDrop={undefined} // Pas de gestion de drop pour cet exemple
      boardOrientation={color === "w" ? "white" : "black"} // Orientation du plateau
      resizable={false} // Le plateau n'est pas redimensionnable
      enableScrollOnPieces={true}
      allowAnnotations={false}
    />
  );
};

export default ChessboardPresentation;
