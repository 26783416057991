import { useLessonOpeningStore } from "../../../store/useLessonOpeningStore";
import GraduationPanel from "./GraduationPanel/GraduationPanel";
import LearnPanel from "./LearnPanel/LearnPanel";
import TrainingPanel from "./TrainingPanel/TrainingPanel";

const PanelContainer = () => {
  // Gestion de l'état pour savoir si on est en mode "Learn" ou "Training"
  const { mode } = useLessonOpeningStore();

  // Rendre le bon composant en fonction du mode
  let component;

  if (mode === "cours") {
    component = <LearnPanel />;
  } else if (mode === "entrainement") {
    component = <TrainingPanel />;
  } else {
    // Optionnel : un composant par défaut ou rien
    component = <GraduationPanel />;
  }

  return <>{component}</>;
};

export default PanelContainer;
