import React, { useEffect, useState } from "react";
import { useLessonOpeningStore } from "../../store/useLessonOpeningStore";
import { useSettingChessboardStore } from "../../store/useSettingChessboardStore";
import ChessboardCours from "../commun/ChessboardCours/ChessboardCours";
import Panel from "../commun/Panel/Panel";
import ProgressBarComponent from "../commun/ProgressBar/ProgressBarComponent";
import TreeAndMoveDetails from "../TreeAndDetails/TreeAndDetails";
import "./ChessboardCoursEntrainement.scss";

const ChessboardCoursEntrainement: React.FC = () => {
  const { size, setMode } = useSettingChessboardStore();

  const { mode, currentPage, infoOpeningData } = useLessonOpeningStore();

  // Define a breakpoint for mobile devices
  const MOBILE_BREAKPOINT = 768;

  // State to track if the current window size is mobile
  const [isMobileSize, setIsMobileSize] = useState<boolean>(() => {
    // Check if window is defined (important for SSR)
    if (typeof window !== "undefined") {
      return window.innerWidth < MOBILE_BREAKPOINT;
    }
    return false; // Default to desktop if window is not defined
  });

  useEffect(() => {
    // Handler to call on window resize

    const handleResize = () => {
      const isMobile = window.innerWidth < MOBILE_BREAKPOINT;
      setIsMobileSize(isMobile);

      const mode = isMobile ? "vertical" : "horizontal";
      console.log(mode);
      setMode(mode);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler once to set initial mode
    handleResize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setMode, window.innerWidth]);

  return (
    <div className="chessboardCoursEntrainement">
      <div className="chessboardCoursEntrainement_content">
        <div
          className="grid-container"
          style={{
            maxHeight: 1080,
            maxWidth: 1980,
            height: "100%",
            width: "100%",
          }}
        >
          <div
            className="chessboard-item"
            style={
              isMobileSize
                ? {}
                : ({ "--size": `${size}px` } as React.CSSProperties)
            }
          >
            <ChessboardCours />
          </div>
          <div
            className="tree-details-item"
            style={isMobileSize ? {} : { height: size }} // Retire `height` si mobile
          >
            <div className="openingDetails">
              <TreeAndMoveDetails />
              {/*               <Panel />
              {mode === "cours" && (
                <ProgressBarComponent
                  percentage={(currentPage / infoOpeningData.nbrOfPath) * 100}
                />
              )} */}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ChessboardCoursEntrainement;
